import consumer from "../channels/consumer";

function pollActiveMeetingStatus(element) {
  const url = element.data('active-meeting-refresh-url');
  $.get(url, function (data) {
    if (data.data.attributes.active) {
      // Refresh the page
      location.reload();
    }
  });
}

document.addEventListener("DOMContentLoaded", (e) => {
  $('[data-active-meeting-refresh-url]').each(function (index, element) {
    setInterval(() => pollActiveMeetingStatus($(element)), 10000);
  });
});

document.addEventListener("DOMContentLoaded", (e) => {
  if ($("[data-controller='induction']").length < 1) {
    return;
  }
  consumer.subscriptions.create(
    {channel: 'InductionSessionChannel', id: 1},
    {
      connected() {
        console.log('Connected to InductionSessionChannel');
      },
      disconnected() {
        console.log('Disconnected from InductionSessionChannel');
      },
      received(data) {
        console.log('Facilitator has triggered the action: ' + data.action);
        if (data.action === 'close-meeting') {
          const appDialogEl = document.querySelector('#app-meeting-closed-dialog');
          const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
          dialog.show();
        }
      }
    });
});

document.addEventListener("DOMContentLoaded", (e) => {
  if ($("[data-controller='review']").length < 1) {
    return;
  }
  consumer.subscriptions.create(
    {channel: 'ReviewSessionChannel', id: 1},
    {
      connected() {
        console.log('Connected to ReviewSessionChannel');
      },
      disconnected() {
        console.log('Disconnected from ReviewSessionChannel');
      },
      received(data) {
        console.log('Facilitator has triggered the action: ' + data.action);
        if (data.action === 'close-meeting') {
          const appDialogEl = document.querySelector('#app-meeting-closed-dialog');
          const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
          dialog.show();
        }
      }
    });
});

document.addEventListener("DOMContentLoaded", (e) => {
  if ($("[data-controller='goal_setting']").length < 1) {
    return;
  }
  consumer.subscriptions.create(
    {channel: 'GoalSettingSessionChannel', id: 1},
    {
      connected() {
        console.log('Connected to GoalSettingSessionChannel');
      },
      disconnected() {
        console.log('Disconnected from GoalSettingSessionChannel');
      },
      received(data) {
        console.log('Facilitator has triggered the action: ' + data.action);
        if (data.action === 'close-meeting') {
          const appDialogEl = document.querySelector('#app-meeting-closed-dialog');
          const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
          dialog.show();
        }
      }
    });
});
