import 'chartjs-adapter-moment';
import Chart from 'chart.js/auto';

const charts = {
  "PlatformActivity": {
    name: "Platform Activity",
    attribute: 'platform-physical-activity-minutes',
    y_label: 'Minutes'
  },
  "DailyAverageSitting": {
    name: "Daily Average Sitting",
    attribute: 'daily-average-sitting',
    y_label: 'Hours'
  },
  "LegStrength": {
    name: "Leg Strength",
    attribute: 'sit-to-stand-30seconds',
    y_label: 'Sit to Stands in 30 secs'
  },
  "Balance": {
    name: "Balance",
    attribute: 'balance-seconds',
    y_label: 'Balance (seconds)'
  },
  "AdditionalActivity": {
    name: "Additional Activity",
    attribute: 'additional-physical-activity-minutes',
    y_label: 'Minutes'
  },
  "AdditionalActivityWalkingMinutes": {
    name: "Walking in Last 7 Days",
    attribute: 'walking-minutes-total',
    y_label: 'Minutes'
  },
  "AdditionalActivityCyclingMinutes": {
    name: "Cycling in Last 7 Days",
    attribute: 'cycle-minutes-total',
    y_label: 'Minutes'
  },
  "AdditionalActivityFitMinutes": {
    name: "Fitness Activities in Last 7 Days",
    attribute: 'fit-activity-minutes-total',
    y_label: 'Minutes'
  }
};

document.addEventListener('DOMContentLoaded', () => {
    Object.keys(charts).forEach(key => {
      if (!document.getElementById(key)) {
        return;
      }
      getChartData(key, charts[key]);
    });
  }
)

function getChartData(key, chart_attributes) {
  $.getJSON($('#' + key).data('chart-url'), function (result) {
    const values = formatJson(result, chart_attributes.attribute);
    values.sort((a, b) => {
      return new Date(a.x) - new Date(b.x);
    })
    setUpChart(key, chart_attributes, values);
  });
}

function formatJson(result, attribute) {
  return result.data.map(element => {
    const date = element.attributes['created-at'];
    const value = element.attributes[attribute];
    return {
      x: date,
      y: value
    };
  });
}

const setUpChart = (chart_name, chart_attributes, data) => {
  const chartCanvasEl = document.getElementById(chart_name);
  if (chartCanvasEl) {
    const titleEl = document.querySelector(`.chart-title--${chart_name}`);
    if (titleEl) {
      titleEl.innerText = charts[chart_name].name;
    }

    const ctx = chartCanvasEl.getContext('2d');
    const bg_color = getChartBackgroundColor(ctx)
    new Chart(ctx, getConfig(chart_attributes, data, bg_color));
  }
}

const getConfig = (chart_attributes, chart_data, bg_color) => {
  const data = {
    labels: [],
    datasets: [{
      label: chart_attributes.name,
      fill: true,
      backgroundColor: bg_color,
      borderColor: '#F27630',
      pointBackgroundColor: '#694E94',
      pointBorderColor: 'white',
      pointBorderWidth: 3,
      pointRadius: 5,
      data: chart_data,
      cubicInterpolationMode: 'monotone',
      tension: 0.4
    }]
  };

  const config = {
    type: 'line',
    data: data,
    options: {
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
        }
      },
      scales: {
        x: {
          type: 'time',
          ticks: {
            source: 'data'
          },
          time: {
            unit: 'week'
          },
          title: {
            display: true,
            text: 'Time'
          }
        },
        y: {
          min: 0,
          title: {
            display: true,
            text: chart_attributes.y_label
          }
        }
      },
    }
  };
  return config;
};

const getChartBackgroundColor = (ctx) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, 159);
  gradient.addColorStop(0, '#F7AC82');
  gradient.addColorStop(1, '#FFFFFF');
  return gradient;
}
