import CalendarController from './calendar_controller';

/**
 * Admin user controlling calendar of schedules for a specific session content
 */
export default class extends CalendarController {
  connect() {
    const categoryId = this.data.get('categoryId');
    const contentId = this.data.get('contentId');
    const url = `/admin/connected_sessions/categories/${categoryId}/contents/${contentId}/calendar.json`

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch the calendar data.');
        }
      })
      .then(() => {
        // URLs
        this.setGetIndexURL(url);
        this.setDefaultCalendarId('MoveMoreSession');

        // Data control.
        this.getCalendarData();
        this.setupUpdateCalendarEvent();
        this.setupDeleteCalendarEvent();
        this.setupCreateCalendarEvent();
      })
      .catch(error => {
        alert(`${error}`);
      });

    // Calendar init and buttons.
    this.initCalendar();
    this.setupOnPrev();
    this.setupOnNext();
    this.setupOnToday();
    this.setupCalendarViews();

    // Handle refreshing the page.
    this.calendar.changeView(this.viewSelection.value, true);
    this.updateDateHeaders();
  }

  setupCreateCalendarEvent() {
    this.calendar.on('beforeCreateSchedule', (creatingSchedule) => {
      this.#createScheduleOverride(this.calendar, creatingSchedule);
    });
  }

  #createScheduleOverride(calendar, creatingSchedule) {
    this.dispatch('clickScheduleMoveMoreSession', { detail: { creatingSchedule, calendar } })
  }
}
