// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("./booking_calendar")
require("./chart")
require("./mediaplayer")
require("./toggle_if")
require("./livekit")
require("./subscriptions")
require("./subscription_prompt")
require('./video_uploading')
require("./move_more_sessions")
require("./welcome_review_meetings")
require("./cookie_banner")

const jquery = require('jquery');
window.$ = window.jQuery = jquery;

const moment = require("moment");
window.moment = moment;

const bootstrap = require('bootstrap');
window.bootstrap = bootstrap;

import '../sb-admin-pro/js/scripts';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// For stimulus.js
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
application.debug = true;


// Slider Bubble
function setSliderBubble(range) {
  const newValue = Number( (range.value - range.min) * 100 / (range.max - range.min) ),
    newPosition = 10 - (newValue * 0.2);

  $('#' + range.id + '-bubble').html('<span>' + $('#' + range.id).val() + '</span>');
  $('#' + range.id + '-bubble').css('left', `calc(${newValue}% + (${newPosition}px))`);
}
$(document).ready(function() {
  $("input[type='range']").each(function (idx, rangeElement) {
    const rangeBubble = $('<div>').attr({id: rangeElement.id + '-bubble', class: 'range-bubble'});
    rangeBubble.insertBefore(rangeElement);
    setSliderBubble(rangeElement);
    $(rangeElement).on('propertychange input', function(e) {
      setSliderBubble(e.target);
    });
  });
});
