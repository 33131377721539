import { Controller } from 'stimulus'

export default class extends Controller {
  flash({ detail: { message } }) {
    const notifMsg = document.getElementById('toast-message');
    notifMsg.innerText = message;
    this.showNotif();
  }

  showNotif() {
    const notifBox = document.getElementById('toast')
    const toast = new bootstrap.Toast(notifBox);
    toast.show()
  }
}
