import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'sexualOrientation',
    'otherSexualOrientation',
    'otherSexualOrientationContainer'
  ]

  connect() {
    this.onSexualOrientation();
  }

  onSexualOrientation() {
    const value = parseInt(this.sexualOrientationTarget.value, 10);

    // When selecting the 'Others (please specify)' option (5),
    // show the text input to enter the other sexual orientation.

    if (value !== 5) {
      this.#setElementEnabled(this.otherSexualOrientationContainerTarget, this.otherSexualOrientationTarget, false);
      this.otherSexualOrientationTarget.value = '';
      return;
    }

    this.#setElementEnabled(this.otherSexualOrientationContainerTarget, this.otherSexualOrientationTarget);
  }

  #setElementEnabled(container, element, isEnabled = true) {
    if (isEnabled) {
      container.classList.remove('d-none');
    } else {
      container.classList.add('d-none');
    }
    element.toggleAttribute('disabled', !isEnabled);
  }
}
