import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import moment from 'moment';
import Calendar from 'tui-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import { isDeviceWidthLargeOrLess } from '../packs/device_width';

export default class extends Controller {
  initCalendar() {
    const recommendedCalendarView = this.getRecommendedView();
    this.#getViewSelectionElement().value = recommendedCalendarView;

    this.calendar = new Calendar('#calendar', {
      usageStatistics: false,
      defaultView: recommendedCalendarView,
      taskView: false,
      scheduleView: ['time'],
      useCreationPopup: false,
      useDetailPopup: false,
      calendars,
      week: {
        startDayOfWeek: 1,
      },
      template: {
        allday: function(schedule) {
          return schedule.title
        },
        time: function(schedule) {
          return schedule.title
        },
      },
      theme: this.getTheme()
    });

    this.setupTimezone();

    this.calendar.on({
      clickSchedule: (event) => {
        const schedule = event.schedule;
        const calendarId = event.schedule.calendarId;
        const calendar = this.calendar;
        const dispatchEventName = `clickSchedule${schedule.raw.className}`;

        this.dispatch(dispatchEventName, { detail: { schedule, calendarId, calendar } })
      }
    })

    this.setupCalendarResponsiveness();
    this.updateDateHeaders();
    this.calendar.scrollToNow('smooth');
  };

  /**
   * Controls whether or not to show "day" view on smaller devices, and "weekly" view on larger
   * based on the current window width.
   */
  setupCalendarResponsiveness() {
    window.addEventListener('resize', () => {
      // Update view if necessary
      const recommendedView = this.getRecommendedView();
      this.#getViewSelectionElement().value = recommendedView;
      this.calendar.changeView(recommendedView);

      // And update date header accordingly
      this.updateDateHeaders();
    });
  }

  /**
   * Gets the recommended calendar view to use
   * based on the current window width.
   *
   * @returns {string} - Day if on medium or down, otherwise week / monthly view
   * (whichever of two is already selected - defaults to week)
   */
  getRecommendedView() {
    // On large or down, use day view, otherwise week view
    if (isDeviceWidthLargeOrLess()) {
      return 'day';

    } else {
      // If month is already preferred, then use monthly view, otherwise defaul to week
      let current;
      if (this.calendar) {
        current = this.calendar.getViewName();
      }
      return current === 'month' ? 'month' : 'week';
    }
  }

  /**
   * Change timezone to Europe/London, if not that.
   */
  setupTimezone() {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (currentTimezone === 'Europe/London') {
      // Already Europe/London, ignore.
      return;
    }

    // Change to Europe/London
    this.calendar.setOptions({
      timezone: {
        zones: [
          {
            timezoneName: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
            displayLabel: 'Local Time',
            tooltip: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`
          },
          {
            timezoneName: 'Europe/London',
            displayLabel: 'UK Time',
            tooltip: 'Europe/London'
          }
        ]
      }
    });
  }

  updateDateHeaders() {
    const header = this.#getHeadingElement();
    const subheader = this.#getSubheadingElement();

    const startDate = moment(this.calendar.getDateRangeStart().toDate());
    const endDate = moment(this.calendar.getDateRangeEnd().toDate());

    const currentViewMode = this.calendar.getViewName();

    // Whether or not the selected day / month / week is today
    const isToday = moment(startDate).add(currentViewMode === 'month' ? 15 : 0, 'days').isSame(moment(), currentViewMode);

    let headerText = '', subheaderText = '';
    switch (currentViewMode) {
      case 'day':
        headerText = startDate.format('MMMM YYYY');
        break;

      case 'month':
        // Monthly view shows some dates from last / next month. Meaning that start date can be
        // of last month. Because of that we add some to start in order to ensure that the
        // month we're reporting we're on is correct.
        headerText = startDate.add(15, 'days').format('MMMM YYYY');
        break;

      default: // week
        if (startDate.isSame(endDate, 'month')) {
          // Week dates starts and ends on the same month,
          // Show current month:
          headerText = startDate.format('MMMM YYYY');

        } else if (startDate.isSame(endDate, 'year')) {
          // Week dates starts and ends on the different months, but same year,
          // Show both months and same year:
          headerText = startDate.format('MMMM').concat(' to ').concat(endDate.format('MMMM YYYY'));

        } else {
          // Week dates starts and ends on the different months and year,
          // Show both months and years:
          headerText = startDate.format('MMMM YYYY').concat(' to ').concat(endDate.format('MMMM YYYY'));
        }
      break;
    }
    header.innerText = headerText;
    subheader.innerText = subheaderText;

    // Highlight the date headers if today - hide subheader if no information to display in it
    header.classList.toggle('text-primary', isToday);

    subheader.classList.toggle('text-primary', isToday);
    subheader.classList.toggle('d-none', !isToday || !subheaderText);

    // Handle whether or not should the "Jump to today" button be disabled.
    const todayButton = this.#getTodayButtonElement();
    todayButton.toggleAttribute('disabled', isToday);
  }

  /**
   * Jump to previous date / month / week.
   */
  setupOnPrev() {
    const previousButton = this.#getPreviousButtonElement();
    previousButton.addEventListener('click', () => {
      this.calendar.prev();
      this.updateDateHeaders();
    });
  }

  /**
   * Jump to next date / month / week.
   */
  setupOnNext() {
    const nextButton = this.#getNextButtonElement();
    nextButton.addEventListener('click', () => {
      this.calendar.next();
      this.updateDateHeaders();
    });
  }

  /**
   * Jump to today's date.
   */
  setupOnToday() {
    const todayButton = this.#getTodayButtonElement();
    todayButton.addEventListener('click', () => {
      this.calendar.today();
      this.updateDateHeaders();
      this.calendar.scrollToNow('smooth');
    });
  }

  /**
   * Setup and handle calendar view selection changes
   * between day / week / month.
   */
  setupCalendarViews() {
    const viewSelection = this.#getViewSelectionElement();
    this.viewSelection = viewSelection;
    viewSelection.addEventListener('change', (event) => {
      const selectedView = event.target.value;
      this.calendar.changeView(selectedView, true);
      this.updateDateHeaders();
    });
  }

  /**
   * Returns TUI calendar theme to use on init.
   *
   * @returns {ThemeObject} - https://github.com/nhn/tui.calendar/blob/main/docs/en/apis/theme.md#theme-object
   */
  getTheme() {
    const siteStyle = getComputedStyle(document.body);

    return {
      'week.timegridHalfHour.height': '33px',
      'week.timegridOneHour.height': '66px',
      'common.holiday.color': siteStyle.getPropertyValue('--bs-danger-soft'),
      'week.today.color': siteStyle.getPropertyValue('--bs-primary'),
      'month.today.color': siteStyle.getPropertyValue('--bs-primary'),
      'day.today.color': siteStyle.getPropertyValue('--bs-primary'),
      'common.today.color': siteStyle.getPropertyValue('--bs-primary'),
    }
  }

  setGetIndexURL(url) { this.getIndexURL = url; }
  setGetShowURL(url) { this.getShowURL = url; }
  setCreateURL(url) { this.createURL = url; }
  setDefaultCalendarId(id) { this.calId = id; }

  getCalendarData() {
    fetch(this.getIndexURL)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Failed to get 200 Response while fetching calendar data.');
    })
    .then(response => response.data.forEach(swh => {
      this.calendar.createSchedules([
        {
          id: swh.id,
          calendarId: swh.attributes['calendar-id'],
          title: swh.attributes['event-title'],
          category: 'time',
          start: swh.attributes['start-time'],
          end: swh.attributes['end-time'],
          raw: {
            className: swh.attributes['class-name'],
            updateUrl: swh.attributes['update-url'],
            showUrl: swh.attributes['show-url'],
            formModel: swh.attributes['form-model'],
            canUpdate: swh.attributes['can-update'],
            canDelete: swh.attributes['can-delete'],
            historyStart: [swh.attributes['start-time']],
            historyEnd: [swh.attributes['end-time']],
            sessionContentId: swh.attributes['session-content-id'],
            facilitatorId: swh.attributes['facilitator-id'],
            facilitatorName: swh.attributes['facilitator-name'],
            participantMax: swh.attributes['participant-max'],
            calendarSessionUrl: swh.attributes['calendar-session-url'],
            slotType: swh.attributes['slot-type'],
            forWelcomeMeetingSession: swh.attributes['for-welcome-meeting-session'],
            forReviewSession: swh.attributes['for-review-session'],
            forGoalSettingSession: swh.attributes['for-goal-setting-session'],
            forConnectedSession: swh.attributes['for-connected-session'],
          }
        }
      ])
    }))
    .catch(error => {
      alert(`Could not fetch the calendar data. ${error}`);
    });
  }

  #getHeadingElement() {
    return document.querySelector('#calendar-heading');
  }

  #getSubheadingElement() {
    return document.querySelector('#calendar-subheading');
  }

  #getViewSelectionElement() {
    return document.querySelector('#calendar-view-selection');
  }

  #getPreviousButtonElement() {
    return document.querySelector('#calendar-previous-button');
  }

  #getTodayButtonElement() {
    return document.querySelector('#calendar-today-button');
  }

  #getNextButtonElement() {
    return document.querySelector('#calendar-next-button');
  }

  ////////////////////////////////////////////////////////////////////////////////

  /**
   * @deprecated - Prefer using events setup on the its own controller instead
   */
  setupCreateCalendarEvent() {
    this.calendar.on('beforeCreateSchedule', (event) => this.#createSchedule(this.calendar, event))
  }

  /**
   * @deprecated - Prefer using events setup on the its own controller instead
   */
  #createSchedule(calendar, event) {
    if (!this.createURL) {
      event.guide.clearGuideElement();
      return;
    }

    const schedule = {
      calendarId: this.calId,
      title: 'Working Hours',
      category: 'time',
      start: event.start,
      end: event.end,
      raw: {
        historyStart: [event.start],
        historyEnd: [event.end]
      }
    }
    const formNewData = new FormData();
    formNewData.append('[staff_working_hour]start_time', schedule.start.toDate());
    formNewData.append('[staff_working_hour]end_time', schedule.end.toDate());

    Rails.ajax({
      type: 'POST',
      url: `${this.createURL}.json`,
      data: formNewData,
      success: (response) => {
        schedule.id = response.data.id;
        schedule.calendarId = response.data.attributes['calendar-id'];
        schedule.title = response.data.attributes['event-title'];
        schedule.raw.className = response.data.attributes['class-name'];
        schedule.raw.updateUrl = response.data.attributes['update-url'];
        schedule.raw.showUrl = response.data.attributes['show-url'];
        schedule.raw.formModel = response.data.attributes['form-model'];
        schedule.raw.canUpdate = response.data.attributes['can-update'];
        schedule.raw.canDelete = response.data.attributes['can-delete'];
        schedule.raw.sessionContentId = response.data.attributes['session-content-id'];
        schedule.raw.facilitatorId = response.data.attributes['facilitator-id'];
        schedule.raw.facilitatorName = response.data.attributes['facilitator-name'];
        schedule.raw.participantMax = response.data.attributes['participant-max'];
        schedule.raw.calendarSessionUrl = response.data.attributes['calendar-session-url'];
        schedule.raw.slotType = response.data.attributes['slot-type'];
        schedule.raw.forWelcomeMeetingSession = response.data.attributes['for-welcome-meeting-session'];
        schedule.raw.forReviewSession = response.data.attributes['for-review-session'];
        schedule.raw.forGoalSettingSession = response.data.attributes['for-goal-setting-session'];
        schedule.raw.forConnectedSession = response.data.attributes['for-connected-session'];

        calendar.createSchedules([schedule]);

        this.dispatch('flash', { detail: { message: 'The calendar event has been successfully created.' } });
      },
      error: (err) => {
        this.dispatch('flash', { detail: { message: err } });
      }
    });
  }

  ////////////////////////////////////////////////////////////////////////////////

  setupDeleteCalendarEvent() {
    this.calendar.on('beforeDeleteSchedule', (event) => this.#deleteSchedule(this.calendar, event));
  }

  deleteFromModal({ detail: { schedule } }) {
    this.#deleteSchedule(this.calendar, { schedule })
  }

  #deleteSchedule(calendar, event) {
    const schedule = event.schedule;
    if (!schedule.raw.canDelete) { return; }

    calendar.deleteSchedule(schedule.id, schedule.calendarId)
    Rails.ajax({
      type: 'DELETE',
      url: schedule.raw.updateUrl,
      success: () => {
        this.#recalculateOverlapping(schedule)
        this.dispatch('flash', { detail: { message: 'The calendar event has been successfully deleted.' } })
      },
      error: () => {
        this.dispatch('flash', { detail: { message: 'Event could not be deleted' } })
      }
    })
  }

  ////////////////////////////////////////////////////////////////////////////////

  setupUpdateCalendarEvent() {
    this.calendar.on('beforeUpdateSchedule', (event) => this.#updateSchedule(this.calendar, event))
  }

  updateFromModal({ detail: { event } }) {
    this.#updateSchedule(this.calendar, event)
  }

  #updateSchedule(calendar, event, updateOverlapping = true) {
    let schedule = event.schedule;
    if (!schedule.raw.canUpdate) {
      alert("You can't reschedule this event.");
      return;
    }

    const changes = event.changes;
    if (!changes) {
      return;
    }

    const [formData, optimisticUpdates] = this.#prepareUpdateData(schedule, changes);
    if (event.formData) {
      this.#mergeFormData(formData, event.formData)
    }

    Rails.ajax({
      type: 'PATCH',
      url: schedule.raw.updateUrl,
      data: formData,
      success: (response) => {

        // If the response is an array, then it has been repeated.
        // We need to render each schedule to the calendar
        if (Array.isArray(response.data)) {
          const schedules = this.#arrayFromRails(response.data)
          this.#renderSchedules(schedules)

        // If the response is not an array, then this is just the old-style single update.
        } else {
          schedule.id = response.data.id;
          schedule.calendarId = response.data.attributes['calendar-id']
          schedule.title = response.data.attributes['event-title'];
          schedule.raw.className = response.data.attributes['class-name'];
          schedule.raw.updateUrl = response.data.attributes['update-url'];
          schedule.raw.showUrl = response.data.attributes['show-url'];
          schedule.raw.formModel = response.data.attributes['form-model'];
          schedule.raw.canUpdate = response.data.attributes['can-update'];
          schedule.raw.canDelete = response.data.attributes['can-delete'];
          schedule.raw.sessionContentId = response.data.attributes['session-content-id'];
          schedule.raw.facilitatorId = response.data.attributes['facilitator-id'];
          schedule.raw.facilitatorName = response.data.attributes['facilitator-name'];
          schedule.raw.participantMax = response.data.attributes['participant-max'];
          schedule.raw.calendarSessionUrl = response.data.attributes['calendar-session-url'];
          schedule.raw.slotType = response.data.attributes['slot-type'];
          schedule.raw.forWelcomeMeetingSession = response.data.attributes['for-welcome-meeting-session'];
          schedule.raw.forReviewSession = response.data.attributes['for-review-session'];
          schedule.raw.forGoalSettingSession = response.data.attributes['for-goal-setting-session'];
          schedule.raw.forConnectedSession = response.data.attributes['for-connected-session'];

          calendar.updateSchedule(schedule.id, schedule.calendarId, optimisticUpdates);
          this.#updateEventTitle(calendar, schedule.id)

          schedule = this.#updateHistory(schedule, changes);
          if (updateOverlapping) {
            this.#recalculateOverlapping(schedule)
          }
        }

        this.dispatch('flash', { detail: { message: 'The calendar event has been successfully updated.' } });
      },
      error: (errorMessage) => {
        this.dispatch('flash', { detail: { message: errorMessage } });
      }
    });
  }

  // Take a response.data object from the server and convert it into an array of schedules.
  #arrayFromRails(responseArray) {
    let schedules = []

    responseArray.forEach((data) => {
      const startTime = data.attributes['start-time']
      const endTime = data.attributes['end-time']
      const schedule = {
        category: 'time',
        raw: {
          historyStart: [startTime],
          historyEnd: [endTime]
        }
      }
      schedule.id = data.id
      schedule.calendarId = data.attributes['calendar-id']
      schedule.title = data.attributes['event-title']
      schedule.start = startTime
      schedule.end = endTime
      schedule.raw.className = data.attributes['class-name']
      schedule.raw.updateUrl = data.attributes['update-url']
      schedule.raw.showUrl = data.attributes['show-url']
      schedule.raw.formModel = data.attributes['form-model']
      schedule.raw.canUpdate = data.attributes['can-update']
      schedule.raw.canDelete = data.attributes['can-delete']
      schedule.raw.sessionContentId = data.attributes['session-content-id']
      schedule.raw.facilitatorId = data.attributes['facilitator-id']
      schedule.raw.facilitatorName = data.attributes['facilitator-name']
      schedule.raw.participantMax = data.attributes['participant-max']
      schedule.raw.calendarSessionUrl = data.attributes['calendar-session-url']
      schedule.raw.slotType = data.attributes['slot-type']
      schedule.raw.forWelcomeMeetingSession = data.attributes['for-welcome-meeting-session']
      schedule.raw.forReviewSession = data.attributes['for-review-session']
      schedule.raw.forGoalSettingSession = data.attributes['for-goal-setting-session']
      schedule.raw.forConnectedSession = data.attributes['for-connected-session']

      schedules.push(schedule)
    }, this)

    return schedules
  }

  // Render an array of schedules to the calendar.
  #renderSchedules(schedules) {
    schedules.forEach((schedule) => {
      const existingEvent = this.calendar.getSchedule(schedule.id, schedule.calendarId)
      if (existingEvent) {
        this.calendar.updateSchedule(schedule.id, schedule.calendarId, schedule)
      } else {
        this.calendar.createSchedules([schedule])
      }
    })
  }

  // After an event is updated, update the title on the calendar.
  #updateEventTitle(calendar, scheduleId) {
    if (this.getShowURL === undefined) { return }

    fetch(this.getShowURL + scheduleId + '.json')
    .then(response => {
      if (response.ok) { return response.json() }
      throw new Error('Failed to get 200 Response while fetching calendar data.')
    })
    .then(response => {
      const id = response.data.id
      const calendarId = response.data.attributes['calendar-id']
      const title = response.data.attributes['event-title']
      calendar.updateSchedule(id, calendarId, { title: title })
    })
    .catch(error => {
      alert(`Could not fetch the calendar data. ${error}`)
    })
  }

  #prepareUpdateData(schedule, changes) {
    /** @type {EventObject} */
    const optimisticUpdates = {...schedule};

    const formData = new FormData();

    if (changes.start) {
      // Send dates / times in UTC ISO8601
      if (changes.start._date) {
        changes.start = moment(changes.start._date).toISOString();
      }
      formData.append(`[${schedule.raw.formModel}]start_time`, changes.start)
      optimisticUpdates.start = changes.start;
    }
    if (changes.end) {
      // Send dates / times in UTC ISO8601
      if (changes.end._date) {
        changes.end = moment(changes.end._date).toISOString();
      }
      formData.append(`[${schedule.raw.formModel}]end_time`, changes.end);
      optimisticUpdates.end = changes.end;
    }
    if (changes.facilitatorId) {
      formData.append(`[${schedule.raw.formModel}]facilitator_id`, changes.facilitatorId);
      optimisticUpdates.raw.facilitatorId = changes.facilitatorId;
      optimisticUpdates.raw.facilitatorName = changes.facilitatorName;
    }
    if (changes.participantMax) {
      formData.append(`[${schedule.raw.formModel}]participant_max`, changes.participantMax);
      optimisticUpdates.raw.participantMax = changes.participantMax;
    }
    if (changes.sessionContentId) {
      formData.append(`[${schedule.raw.formModel}]session_content_id`, changes.sessionContentId);
      optimisticUpdates.raw.sessionContentId = changes.sessionContentId;
    }
    if (changes.slotType) {
      formData.append(`[${schedule.raw.formModel}]slot_type`, changes.slotType);
      optimisticUpdates.raw.slotType = changes.slotType;
    }
    if (changes.forWelcomeMeetingSession !== undefined) {
      formData.append(`[${schedule.raw.formModel}]for_welcome_meeting_session`, changes.forWelcomeMeetingSession);
      optimisticUpdates.raw.forWelcomeMeetingSession = changes.forWelcomeMeetingSession;
    }
    if (changes.forReviewSession !== undefined) {
      formData.append(`[${schedule.raw.formModel}]for_review_session`, changes.forReviewSession);
      optimisticUpdates.raw.forReviewSession = changes.forReviewSession;
    }
    if (changes.forGoalSettingSession !== undefined) {
      formData.append(`[${schedule.raw.formModel}]for_goal_setting_session`, changes.forGoalSettingSession);
      optimisticUpdates.raw.forGoalSettingSession = changes.forGoalSettingSession;
    }
    if (changes.forConnectedSession !== undefined) {
      formData.append(`[${schedule.raw.formModel}]for_connected_session`, changes.forConnectedSession);
      optimisticUpdates.raw.forConnectedSession = changes.forConnectedSession;
    }

    return [formData, optimisticUpdates]
  }

  ////////////////////////////////////////////////////////////////////////////////

  // Set the 'raw' object to the previous values of the dates.
  // We use this to keep a history of date changes.
  #updateHistory(schedule, changes) {
    changes.raw = changes.raw || {}
    changes.raw.className = schedule.raw.className
    changes.raw.updateUrl = schedule.raw.updateUrl
    changes.raw.showUrl = schedule.raw.showUrl
    changes.raw.formModel = schedule.raw.formModel
    changes.raw.canUpdate = schedule.raw.canUpdate
    changes.raw.canDelete = schedule.raw.canDelete
    changes.raw.canDelete = schedule.raw.canDelete
    changes.raw.historyStart = schedule.raw.historyStart
    changes.raw.historyStart.unshift(schedule.start.toDate())
    changes.raw.historyEnd = schedule.raw.historyEnd
    changes.raw.historyEnd.unshift(schedule.end.toDate())
    changes.raw.facilitatorId = changes.facilitatorId ?? schedule.raw.facilitatorId
    changes.raw.facilitatorName = changes.facilitatorName ?? schedule.raw.facilitatorName
    changes.raw.sessionContentId = changes.sessionContentId ?? schedule.raw.sessionContentId
    changes.raw.participantMax = changes.participantMax ?? schedule.raw.participantMax
    return changes
  }

  // Update any previously overlapping events.
  #recalculateOverlapping(originalSchedule) {
    this.#forEachSchedule((item) => {
      if (item.id !== originalSchedule.id && item.calendarId === 'invalid') {
        const customEvent = { schedule: item, changes: {} }
        this.#updateSchedule(this.calendar, customEvent, false)
      }
    })
  }

  // Loop through each schedule in the calendar.
  #forEachSchedule(callback) {
    const items = this.calendar._controller.schedules.items
    const keys = Object.keys(items)
    for (let i in keys) {
      callback(items[keys[i]])
    }
  }

  #mergeFormData(formData1, formData2) {
    for (const pair of formData2.entries()) {
      formData1.append(pair[0], pair[1])
    }
    return formData1
  }
}

// Calendar layout.
const colours = {
  StaffWorkingHour:   { fore: '#ffffff', back: '#7f58af'},
  MoveMoreSession:    { fore: '#ffffff', back: '#f27630'},
  InductionSession:   { fore: '#ffffff', back: '#00cfd5'},
  ReviewSession:      { fore: '#ffffff', back: '#00ac69'},
  GoalSettingSession: { fore: '#ffffff', back: '#f4a100'},
}
const calendars = [
  {
    id: 'AppointmentSlot_available',
    name: 'AppointmentSlot_available',
    color: '#000000',
    bgColor: '#cccccc',
    dragBgColor: '#cccccc',
    borderColor: '#cccccc'
  }, {
    id: 'AppointmentSlot_booked_review',
    name: 'AppointmentSlot_booked_review',
    color: colours.ReviewSession.fore,
    bgColor: colours.ReviewSession.back,
    dragBgColor: colours.ReviewSession.back,
    borderColor: colours.ReviewSession.back
  }, {
    id: 'AppointmentSlot_booked_induction',
    name: 'AppointmentSlot_booked_induction',
    color: colours.InductionSession.fore,
    bgColor: colours.InductionSession.back,
    dragBgColor: colours.InductionSession.back,
    borderColor: colours.InductionSession.back
  }, {
    id: 'AppointmentSlot_booked_connected',
    name: 'AppointmentSlot_booked_connected',
    color: colours.MoveMoreSession.fore,
    bgColor: colours.MoveMoreSession.back,
    dragBgColor: colours.MoveMoreSession.back,
    borderColor: colours.MoveMoreSession.back
  }, {
    id: 'AppointmentSlot_booked_goal_setting',
    name: 'AppointmentSlot_booked_goal_setting',
    color: colours.GoalSettingSession.fore,
    bgColor: colours.GoalSettingSession.back,
    dragBgColor: colours.GoalSettingSession.back,
    borderColor: colours.GoalSettingSession.back
  }, {
    id: 'StaffWorkingHour',
    name: 'StaffWorkingHour',
    color: colours.StaffWorkingHour.fore,
    bgColor: colours.StaffWorkingHour.back,
    dragBgColor: colours.StaffWorkingHour.back,
    borderColor: colours.StaffWorkingHour.back
  }, {
    id: 'MoveMoreSession',
    name: 'MoveMoreSession',
    color: colours.MoveMoreSession.fore,
    bgColor: colours.MoveMoreSession.back,
    dragBgColor: colours.MoveMoreSession.back,
    borderColor: colours.MoveMoreSession.back
  }, {
    id: 'InductionSession',
    name: 'InductionSession',
    color: colours.InductionSession.fore,
    bgColor: colours.InductionSession.back,
    dragBgColor: colours.InductionSession.back,
    borderColor: colours.InductionSession.back
  }, {
    id: 'ReviewSession',
    name: 'ReviewSession',
    color: colours.ReviewSession.fore,
    bgColor: colours.ReviewSession.back,
    dragBgColor: colours.ReviewSession.back,
    borderColor: colours.ReviewSession.back
  }, {
    id: 'GoalSettingSession',
    name: 'GoalSettingSession',
    color: colours.GoalSettingSession.fore,
    bgColor: colours.GoalSettingSession.back,
    dragBgColor: colours.GoalSettingSession.back,
    borderColor: colours.GoalSettingSession.back
  }, {
    id: 'invalid',
    name: 'Error',
    color: '#ffffff',
    bgColor: '#565656',
    dragBgColor: '#565656',
    borderColor: '#565656'
  }
];
