import { Controller } from 'stimulus'
import moment from 'moment';

export default class extends Controller {
  static targets = [
    'header', 'startTime', 'endTime', 'deleteButton', 'showButton', 'updateButton'
  ]

  connect() {
    this.schedule = null
  }

  show({ detail: { schedule } }) {
    this.schedule = schedule
    $(this.element).modal('show')

    this.headerTarget.innerHTML = this.#modalHeader(schedule)
    this.startTimeTarget.valueAsNumber = this.#writeDateWithoutTimeZone(schedule.start._date).valueOf();
    this.endTimeTarget.valueAsNumber = this.#writeDateWithoutTimeZone(schedule.end._date).valueOf();
    this.#showButton(schedule)

    if (schedule.raw.canUpdate) {
      this.#enableUpdateControls()
    } else {
      this.#disableUpdateControls()
    }

    if (schedule.raw.canDelete) {
      this.#enableDeleteControls()
    } else {
      this.#disableDeleteControls()
    }
  }

  clickDelete(event) {
    event.preventDefault()
    this.dispatch('delete', { detail: { schedule: this.schedule } })
    this.#closeModal()
  }

  clickSubmit(event) {
    event.preventDefault()

    const changes = {
      start: { _date: this.#readDateWithoutTimeZone(this.startTimeTarget.valueAsNumber) },
      end: { _date: this.#readDateWithoutTimeZone(this.endTimeTarget.valueAsNumber) }
    }
    const eventArgs = {
      schedule: this.schedule,
      changes: changes
    }
    this.dispatch('update', { detail: { event: eventArgs } })

    this.#closeModal()
  }

  #closeModal() {
    $(this.element).modal('hide')
    this.schedule = null
  }

  #writeDateWithoutTimeZone(date) {
    return moment(date).utc(true)
  }

  #readDateWithoutTimeZone(date) {
    return new Date(date)
  }

  #modalHeader(schedule) {
    if (schedule.raw.showUrl) {
      return `<a href='${schedule.raw.showUrl}' target='_blank' rel='noopener'>${schedule.title}</a>`
    } else {
      return schedule.title
    }
  }

  #showButton(schedule) {
    this.showButtonTarget.setAttribute('href', schedule.raw.showUrl)
  }

  #enableUpdateControls() {
    this.updateButtonTarget.toggleAttribute('disabled', false)
    this.startTimeTarget.toggleAttribute('disabled', false)
    this.endTimeTarget.toggleAttribute('disabled', false)
  }

  #disableUpdateControls() {
    this.updateButtonTarget.toggleAttribute('disabled', true)
    this.startTimeTarget.toggleAttribute('disabled', true)
    this.endTimeTarget.toggleAttribute('disabled', true)
  }

  #enableDeleteControls() {
    this.deleteButtonTarget.toggleAttribute('disabled', false)
  }

  #disableDeleteControls() {
    this.deleteButtonTarget.toggleAttribute('disabled', true)
  }
}
