import { Controller } from 'stimulus'
import consumer from '../channels/consumer'

export default class extends Controller {
  static targets = [
    'remindMeOfYourOriginalGoal',
    'howAreYouGettingOn',
    'haveYouBeenAbleToRecheck',
    'howHaveYouFoundTheProcess',
    'howDoYouFeelAboutCompleting',
    'howAreYouWithEmotionalWellbeing',
    'coachesNotes',
  ]

  static values = {
    reviewSessionId: Number
  }

  connect() {
    const controller = this
    consumer.subscriptions.create(
      { channel: 'ReviewSessionChannel', id: 1 },
      {
        connected() {
          console.log('Connected to ReviewSessionChannel')
        },
        disconnected() {
          console.log('Disconnected from ReviewSessionChannel')
        },
        received(response) {
          if (response.action === 'update-review-session') {
            if (response.data.data.id == controller.reviewSessionIdValue) {
              controller.#update(response.data.data.attributes)
            }
          }
        }
      }
    )
  }

  #update(attributes) {
    this.#updateText(this.remindMeOfYourOriginalGoalTarget, attributes['remind-me-of-your-original-goal'])
    this.#updateText(this.howAreYouGettingOnTarget, attributes['how-are-you-getting-on'])
    this.#updateText(this.haveYouBeenAbleToRecheckTarget, attributes['have-you-been-able-to-recheck'])
    this.#updateText(this.howHaveYouFoundTheProcessTarget, attributes['how-have-you-found-the-process'])
    this.#updateText(this.howDoYouFeelAboutCompletingTarget, attributes['how-do-you-feel-about-completing'])
    this.#updateText(this.howAreYouWithEmotionalWellbeingTarget, attributes['how-are-you-with-emotional-wellbeing'])
    this.#updateText(this.coachesNotesTarget, attributes['coaches-notes'])
  }

  #updateText(element, value) {
    const text = !value ? '—' : value
    element.innerHTML = text
  }
}
