import { Controller } from 'stimulus'
import consumer from '../channels/consumer'
import moment from 'moment'

export default class extends Controller {
  static targets = [
    'updatedAt',
    'description',
    'whyFocus',
    'preventAchievingGoal',
    'stepsToAchieve1',
    'stepsToAchieve2',
    'stepsToAchieve3',
    'stepsToAchieve4',
    'stepsToAchieve5',
    'coachesNotes',
    'status',
    'goalImportancePercent',
    'goalImportanceValue',
    'confidentAchieveGoalPercent',
    'confidentAchieveGoalValue',
  ]

  connect() {
    const controller = this
    consumer.subscriptions.create(
      { channel: 'GoalSettingSessionChannel', id: 1 },
      {
        connected() {
          console.log('Connected to GoalSettingSessionChannel')
        },
        disconnected() {
          console.log('Disconnected from GoalSettingSessionChannel')
        },
        received(response) {
          if (response.action === 'update-fitness-goal') {
            controller.#update(response.data.data.attributes)
          }
        }
      }
    )
  }

  #update(attributes) {
    this.updatedAtTarget.innerHTML = moment(attributes['updated-at']).format('DD/MM/YYYY HH:mm')

    this.#updateText(this.descriptionTarget,          attributes['description'])
    this.#updateText(this.whyFocusTarget,             attributes['why-focus'])
    this.#updateText(this.preventAchievingGoalTarget, attributes['prevent-achieving-goal'])
    this.#updateText(this.stepsToAchieve1Target,      attributes['steps-to-achieve-1'])
    this.#updateText(this.stepsToAchieve2Target,      attributes['steps-to-achieve-2'])
    this.#updateText(this.stepsToAchieve3Target,      attributes['steps-to-achieve-3'])
    this.#updateText(this.stepsToAchieve4Target,      attributes['steps-to-achieve-4'])
    this.#updateText(this.stepsToAchieve5Target,      attributes['steps-to-achieve-5'])
    this.#updateText(this.coachesNotesTarget,         attributes['coaches-notes'])
    this.#updateText(this.statusTarget,               attributes['status'])

    this.#updateProgressBar(this.goalImportancePercentTarget, attributes['goal-importance'])
    this.goalImportanceValueTarget.innerHTML = attributes['goal-importance']

    this.#updateProgressBar(this.confidentAchieveGoalPercentTarget, attributes['confident-achieve-goal'])
    this.confidentAchieveGoalValueTarget.innerHTML = attributes['confident-achieve-goal']
  }

  #updateText(element, value) {
    const text = !value ? '—' : value
    element.innerHTML = text
  }

  #updateProgressBar(element, value) {
    const percent = value * 10
    element.style.width = `${percent}%`
    element.setAttribute('aria-valuenow', percent)
  }
}
