/**
 * Small (sm) applies to x-small devices (portrait phones, less than 576px).
 *
 * @returns true, if current window is small or less.
 */
export const isDeviceWidthSmallOrLess = () => {
  return window.matchMedia("(max-width: 575.98px)").matches;
}

/**
 * Medium (md) applies to small devices (landscape phones, less than 768px).
 *
 * @returns true, if current window is medium or less.
 */
export const isDeviceWidthMediumOrLess = () => {
  return window.matchMedia("(max-width: 767.98px)").matches;
}

/**
 * Large (lg) applies to medium devices (tablets, less than 992px).
 *
 * @returns true, if current window is large or less.
 */
export const isDeviceWidthLargeOrLess = () => {
  return window.matchMedia("(max-width: 991.98px)").matches;
}

/**
 * Extra large (xl) applies to large devices (desktops, less than 1200px).
 *
 * @returns true, if current window is extra-large or less.
 */
export const isDeviceWidthExtraLargeOrLess = () => {
  return window.matchMedia("(max-width: 1199.98px)").matches;
}

/**
 * Double extra large (xxl) applies to x-large devices (large desktops, less than 1400px).
 *
 * @returns true, if current window is double-extra-large or less
 */
export const isDeviceWidthDoubleExtraLargeOrLess = () => {
  return window.matchMedia("(max-width: 1399.98px)").matches;
}
