document.addEventListener("DOMContentLoaded", (e) => {
  const cancelLink = $('[data-cancel-subscription-dialog]');
  if (cancelLink.length === 0) {
    // Exit if the page isn't a subscription page
    return;
  }

  cancelLink.click(function(e) {
    e.preventDefault();

    // Show cancel dialog
    const appDialogEl = document.querySelector('#' + cancelLink.data('cancel-subscription-dialog'));
    const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static' });
    dialog.show();

    return false;
  })
});
