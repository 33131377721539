import CalendarController from './calendar_controller';

/**
 * Admin user controlling calendar of schedules for all connected sessions
 */
export default class extends CalendarController {
  connect() {
    fetch(`/calendar/move_more_sessions.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch the calendar data.');
        }
      })
      .then(() => {
        // URLs
        this.setGetIndexURL(`/calendar/move_more_sessions.json`);
        this.setDefaultCalendarId('MoveMoreSession');

        // Data control.
        this.getCalendarData();
        this.setupUpdateCalendarEvent();
        this.setupDeleteCalendarEvent();
        this.setupCreateCalendarEvent();
      })
      .catch(error => {
        alert(`${error}`);
      });

    // Calendar init and buttons.
    this.initCalendar();
    this.setupOnPrev();
    this.setupOnNext();
    this.setupOnToday();
    this.setupCalendarViews();

    // Handle refreshing the page.
    this.calendar.changeView(this.viewSelection.value, true);
    this.updateDateHeaders();
  }

  /* -------------------------------------------------------------------------- */
  /*                                  Overrides                                 */
  /* -------------------------------------------------------------------------- */

  setupCreateCalendarEvent() {
    this.calendar.on('beforeCreateSchedule', (creatingSchedule) => {
      this.#onCreate(this.calendar, creatingSchedule);
    });
  }

  #onCreate(calendar, creatingSchedule) {
    this.dispatch('clickScheduleMoveMoreSession', { detail: { calendar, creatingSchedule } })
  }
}
