document.addEventListener('DOMContentLoaded', () => {
  const monthPicker = document.getElementById('month-picker');
  const dayPicker = document.getElementById('day-picker');
  const slotPicker = document.getElementById('slot-picker');
  if (!monthPicker || !dayPicker || !slotPicker) {
    return;
  }

  const pickerList = slotPicker.getElementsByClassName('picker')[0];
  const title = monthPicker.getElementsByClassName('title')[0];
  const loading = monthPicker.getElementsByClassName('loading')[0];
  const prevMonth = monthPicker.getElementsByClassName('prev')[0];
  const nextMonth = monthPicker.getElementsByClassName('next')[0];
  let {month, year} = monthPicker.dataset;
  const {urlPath} = monthPicker.dataset;
  let currentMoment = moment(`${year}-${month}`, 'YYYY-MM');
  let selected = undefined;
  let monthAvailability = undefined;

  prevMonth.addEventListener('click', function (e) {
    e.preventDefault();

    --month;
    if (month < 1) {
      month = 12;
      --year;
    }

    monthChanged().catch((e) => alert('Something went wrong', e));
  });

  nextMonth.addEventListener('click', function (e) {
    e.preventDefault();

    ++month;
    if (month > 12) {
      month = 1;
      ++year;
    }
    monthChanged().catch(() => alert('Something went wrong'));
  });

  function selectDay(day, e) {
    e.preventDefault();
    if (selected) {
      selected.classList.remove('selected');
    }
    this.classList.add('selected');
    selected = this;
    currentMoment.day(day);
    const slots = monthAvailability.filter(slot => slot.attributes.day == day);

    if (slots.length === 0) {
      replaceSlotPickerListWithMessage('Sorry, no slots available on this day!');
      return;
    }

    // Build slot list elements from slot data
    pickerList.replaceChildren(...slots.map((slot) => {
      const slotDiv = document.createElement('div');
      slotDiv.addEventListener('click', (e) => selectSlot(e.target, year, month, day, Object.assign(slot.attributes, {id: slot.id})));
      slotDiv.classList.add('slot');
      slotDiv.innerText = slot.attributes.slot;
      return slotDiv;
    }));
  }

  function selectSlot(element, year, month, day, slot) {
    // Unselect the previous slot, if selected.
    const prevSelectedSlot = document.querySelector('.slot.selected');
    if (prevSelectedSlot) {
      prevSelectedSlot.classList.remove('selected');
    }

    // Then select the new desired slot
    element.classList.toggle('selected');
    window.onSlotSelected(year, month, day, slot);
  }

  async function monthChanged() {
    loading.style.display = 'block';

    const res = await fetch(`${urlPath}?month=${month}&year=${year}`);
    const availability = await res.json();

    monthAvailability = availability.data;

    currentMoment = moment(`${year}-${month}`, 'YYYY-MM');
    title.innerHTML = currentMoment.format('MMMM YYYY');

    // Reset slot picker and previously selected slot
    replaceSlotPickerListWithMessage('Please select a date!');
    window.onSelectedSlotReset();

    let row = document.createElement('tr');
    const rows = [];
    const days = currentMoment.daysInMonth();
    let weekday = currentMoment.day();
    for (let i = 0; i < weekday; i++) {
      const cell = document.createElement('td');
      row.appendChild(cell);
    }

    for (let i = 1; i <= days; i++) {
      const cell = document.createElement('td');
      const data = monthAvailability.filter(slot => slot.attributes.day == i);
      const date = moment(`${year}-${month}-${i}`, 'YYYY-MM-D');
      const past = date.isBefore(moment(), 'day');

      if (past) {
        cell.classList.add('past');
      } else if (data.length === 0) {
        cell.classList.add('unavailable')
      }
      if (!past) {
        cell.addEventListener('click', selectDay.bind(cell, i));
      }
      cell.innerHTML = i.toString(10);
      row.appendChild(cell);
      ++weekday;
      if (weekday >= 7) {
        rows.push(row);
        row = document.createElement('tr');
        weekday = 0;
      }
    }

    // Pad the last row with cells if needed
    if (weekday > 0) {
      for (let i = weekday; i < 7; i++) {
        const cell = document.createElement('td');
        row.appendChild(cell);
      }
    }

    rows.push(row);
    dayPicker.replaceChildren(...rows);
    loading.style.display = 'none';
  }

  function replaceSlotPickerListWithMessage(message) {
    const slotPickerText = document.createElement('p');
    slotPickerText.classList.add('picker-message');
    slotPickerText.innerText = message;
    pickerList.replaceChildren(slotPickerText);
  }

  monthChanged().catch(() => alert('Something went wrong'));
});
