function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

document.addEventListener("DOMContentLoaded", (e) => {
  const appDialogEl = document.querySelector('#app-cookie-prompt-dialog');
  if (appDialogEl === undefined) {
    return;
  }
  const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
  if (!$("body").data("cookie-prefs")) {
    dialog.show();
  }

  const btnDeny = $("#app-cookie-prompt-dialog-btn-deny");
  const btnCustomise = $("#app-cookie-prompt-dialog-btn-customise");
  const btnAllowSelected = $("#app-cookie-prompt-dialog-btn-allow-selected");
  const btnAllowAll = $("#app-cookie-prompt-dialog-btn-allow-all");

  function submitCookiePreferences() {
    const allowAnalytics = $("#chk-cookies-analytics").is(':checked');
    const body = {
      analytics: allowAnalytics
    }
    $.post('/cookies.json', body, function (data) {
      dialog.hide();
    });
  }

  $('a[href="#reset-cookie-prefs"]').click(function(e) {
    e.preventDefault();
    dialog.show();
  })

  $('#cookie-tabs').on('shown.bs.tab', function (e) {
    if (event.target.id === 'app-cookie-prompt-dialog-btn-customise' || event.target.id === 'cookie-details-tab') {
      btnAllowSelected.removeClass("d-none");
      btnCustomise.addClass("d-none");
    } else {
      btnAllowSelected.addClass("d-none");
      btnCustomise.removeClass("d-none");
    }
  })

  btnDeny.click(function () {
    $("#chk-cookies-analytics").prop('checked', false);
    submitCookiePreferences();
  });
  btnCustomise.click(function () {
    $('#cookie-tabs').find('li:nth-child(2) button').tab('show');
  });
  btnAllowSelected.click(function () {
    submitCookiePreferences();
  });
  btnAllowAll.click(function () {
    $("#chkCookiesAnalytics").prop('checked', true);
    submitCookiePreferences();
  });

});
