document.addEventListener("DOMContentLoaded", (e) => {
  const appDialogEl = document.querySelector('#app-subscription-prompt-dialog');
  if (!appDialogEl) {
    return;
  }
  const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
  if ($('[data-active-subscription-required]').length > 0) {
    dialog.show();
  }
});
