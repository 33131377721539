import CalendarController from './calendar_controller';

/**
 * Admin user controlling a Customer user's calendar
 */
export default class extends CalendarController {
  connect() {
    const customerUserId = this.data.get('customerUserId');

    fetch(`/calendar/customer_users/${customerUserId}/events.json`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch the calendar data.');
      }
    })
    .then(() => {
      // URLs
      this.setGetIndexURL(`/calendar/customer_users/${customerUserId}/events.json`);
      this.setDefaultCalendarId('MoveMoreSession');

      // Data control.
      this.getCalendarData();
      this.setupUpdateCalendarEvent();
      this.setupDeleteCalendarEvent();
      this.setupCreateCalendarEvent();
    })
    .catch(error => {
      alert(`${error}`);
    });

    // Calendar init and buttons.
    this.initCalendar();
    this.setupOnPrev();
    this.setupOnNext();
    this.setupOnToday();
    this.setupCalendarViews();

    // Handle refreshing the page.
    this.calendar.changeView(this.viewSelection.value, true);
    this.updateDateHeaders();
  }
}
