function pollVideoInProgressStatus(element) {
  const url = element.data('join-video-call-refresh-url');
  $.get(url, function (data) {
    if (data.data.attributes['video-in-progress']) {
      // Refresh the page
      location.reload();
    }
  });
}

document.addEventListener("DOMContentLoaded", (e) => {
  $('[data-join-video-call-refresh-url]').each(function (index, element) {
    setInterval(() => pollVideoInProgressStatus($(element)), 10000);
  });
});
