const {BlockBlobClient} = require("@azure/storage-blob");

function uploadProgress(transferProgressEvent) {
  setUploadProgressBar(transferProgressEvent.loadedBytes)
}

function setUploadProgressBar(bytesUploaded) {
  const progressBar = $('.upload-form-progress').find('.progress-bar');
  const fileInput = $('#upload-form-file')[0];
  const totalBytes = fileInput.files[0].size;
  const percentage = (bytesUploaded / totalBytes) * 100.0;
  const roundedPercentage = Math.round(percentage * 10) / 10; // to 1dp
  progressBar.html(roundedPercentage + "%");
  progressBar.css('width', roundedPercentage + "%");
}

async function uploadVideo(dialog, sasUrl, completionUrl, fileToUpload) {
  // Create blob client from SAS token url
  const blockBlobClient = new BlockBlobClient(sasUrl);

  // Upload string
  await blockBlobClient.uploadData(fileToUpload, {onProgress: uploadProgress, maxSingleShotSize: 256 * 1024 * 1024});

  $.post(completionUrl, function (data) {
    // Refresh the page
    location.reload();
  });
}

function pollTranscodedStatus(element) {
  const url = element.data('transcoded-refresh-url');
  console.log(url);
  $.get(url, function (data) {
    if (data.data.attributes.transcoded) {
      // Refresh the page
      location.reload();
    }
  });
}


document.addEventListener("DOMContentLoaded", (e) => {
  $('[data-transcoded-refresh-url]').each(function(index, element) {
    setInterval(() => pollTranscodedStatus($(element)), 10000);
  });
  const appDialogEl = document.querySelector('#app-upload-dialog');
  if (!appDialogEl) {
    return;
  }
  const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
  const uploadButton = $('button[data-upload-sas-url]');
  const sasUrl = uploadButton.data('upload-sas-url');
  const completionUrl = uploadButton.data('upload-complete-url');
  $('button[data-upload-sas-url]').click(function() {
    dialog.show();
  });
  $('.upload-form-cancel').click(function() {
    dialog.hide();
  });
  $('form.upload-form').submit(function (e) {
    e.preventDefault();
    setUploadProgressBar(0);
    $('.upload-form').addClass('d-none');
    $('.upload-form-progress').removeClass('d-none');
    const fileInput = $('#upload-form-file')[0];
    const fileToUpload = fileInput.files[0];
    uploadVideo(dialog, sasUrl, completionUrl, fileToUpload);
  });
});
