import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import moment from 'moment';

export default class extends Controller {

  static targets = [
    'header',
    'date',

    'sessionContentSection',
    'sessionContent',
    'sessionContentMessage',

    'timesSection',
    'startTime',
    'endTime',
    'timesMessage',

    'facilitatorSection',
    'facilitator',
    'facilitatorMessage',
    'busyFacilitator',

    'participantSection',
    'participantMax',
    'participantMaxMessage',

    'viewLink',
    'deleteButton',
    'submitButton',
  ];

  /**
   * @type {('session_content' | 'time_range' | 'facilitator' | 'participant_max')}
   */
  step = 'session_content';

  connect() {
    this.abortController = new AbortController();

    this.element.addEventListener('hidden.bs.modal', () => {
      // On modal hide
      if (this.creatingSchedule) {
        this.creatingSchedule.guide.clearGuideElement();
      }

      this.#reset();
    });
  }

  onDelete(event) {
    event.preventDefault();

    if (this.isEditing && this.editingSchedule) {
      // Delete actual schedule
      this.dispatch('delete', { detail: { schedule: this.editingSchedule } });
    }

    this.#closeModal();
  }

  onSubmit(event) {
    event.preventDefault();

    if (!this.isEditing) {
      // Create
      const { createUrlPath } = this.element.dataset;

      const startTime = this.#getStartTime().toISOString();
      const endTime = this.#getEndTime().toISOString();
      const schedule = {
        calendarId: this.calendarId,
        title: 'Move More',
        category: 'time',
        start: startTime,
        end: endTime,
        raw: {
          historyStart: [startTime],
          historyEnd: [endTime]
        }
      }

      const data = new FormData();
      data.append('[move_more_session]start_time', startTime);
      data.append('[move_more_session]end_time', endTime);
      data.append('[move_more_session]session_content_id', this.sessionContentTarget.value);
      data.append('[move_more_session]facilitator_id', this.facilitatorTarget.value);
      data.append('[move_more_session]participant_max', this.participantMaxTarget.value);

      Rails.ajax({
        type: 'POST',
        url: createUrlPath,
        data: data,
        success: (res) => {
          const createdSchedule = res.data;

          schedule.id = createdSchedule.id;
          schedule.calendarId = createdSchedule.attributes['calendar-id'];
          schedule.title = createdSchedule.attributes['event-title'];
          schedule.raw.className = createdSchedule.attributes['class-name'];
          schedule.raw.updateUrl = createdSchedule.attributes['update-url'];
          schedule.raw.showUrl = createdSchedule.attributes['show-url'];
          schedule.raw.formModel = createdSchedule.attributes['form-model'];
          schedule.raw.canUpdate = createdSchedule.attributes['can-update'];
          schedule.raw.canDelete = createdSchedule.attributes['can-delete'];
          schedule.raw.sessionContentId = createdSchedule.attributes['session-content-id'];
          schedule.raw.facilitatorId = createdSchedule.attributes['facilitator-id'];
          schedule.raw.facilitatorName = createdSchedule.attributes['facilitator-name'];
          schedule.raw.participantMax = createdSchedule.attributes['participant-max'];

          if (this.calendar) {
            this.calendar.createSchedules([schedule]);
          }

          this.dispatch('flash', { detail: { message: 'The calendar event has been successfully created.' } });
        },
        error: (err) => {
          this.dispatch('flash', { detail: { message: err } })
        }
      });

    } else if (this.isEditing && this.editingSchedule) {
      if (!this.editingSchedule.raw.canUpdate) {
        alert("You can't update your working hours");
        this.#closeModal();
        return;
      }

      // Update
      const changes = {};
      if (this.#hasTimeRangeChanged()) {
        changes.start = this.#getStartTime().toISOString();
        changes.end = this.#getEndTime().toISOString();
      }
      if (this.#hasSessionContentChanged()) {
        changes.sessionContentId = this.sessionContentTarget.value;
      }
      if (this.#hasFacilitatorChanged()) {
        changes.facilitatorId = this.facilitatorTarget.options[this.facilitatorTarget.selectedIndex].value;
        changes.facilitatorName = this.facilitatorTarget.options[this.facilitatorTarget.selectedIndex].text;
      }
      if (this.#hasParticipantMaxChanged()) {
        changes.participantMax = this.participantMaxTarget.value;
      }

      const eventArgs = {
        schedule: this.editingSchedule,
        changes: changes
      }
      this.dispatch('update', { detail: { event: eventArgs } });
    }

    // Dismiss the modal
    this.#closeModal();
  }

  show({ detail }) {
    this.calendar = detail.calendar;

    if (detail.schedule) {
      // Editing an existing schedule
      this.isEditing = true;
      this.editingSchedule = detail.schedule;

      this.#showForEditing();

    } else if (detail.creatingSchedule) {
      // Creating a new schedule
      this.isEditing = false;
      this.creatingSchedule = detail.creatingSchedule;

      this.#showForCreating();
    }
  }

  #showForEditing() {
    if (!this.editingSchedule) {
      throw new Error('There is no schedule to edit.');
    }

    this.#setupHeader();

    this.currentStartTime = this.editingSchedule.start.toDate();
    this.currentEndTime = this.editingSchedule.end.toDate();

    this.dateTarget.valueAsDate = this.#getDate();
    this.startTimeTarget.valueAsDate = this.#getStartTime().utc(true).toDate();
    this.endTimeTarget.valueAsDate = this.#getEndTime().utc(true).toDate();
    this.sessionContentTarget.value = this.editingSchedule.raw.sessionContentId;
    this.participantMaxTarget.value = this.editingSchedule.raw.participantMax;

    if (!this.editingSchedule.raw.canUpdate) {
      // Can't update schedule details - not permitted, hide submit button and disable all other input controls
      this.#setElementVisible(this.submitButtonTarget, false);
      this.#setElementEnabled(this.submitButtonTarget, false);

      this.#setElementEnabled(this.sessionContentTarget, false);
      this.#setElementEnabled(this.startTimeTarget, false);
      this.#setElementEnabled(this.endTimeTarget, false);
      this.#setElementEnabled(this.participantMaxTarget, false);
      this.#setElementEnabled(this.facilitatorTarget, false);

    } else {
      // Can submit, so setup the button
      this.submitButtonTarget.classList.remove('btn-primary');
      this.submitButtonTarget.classList.add('btn-secondary');
      this.submitButtonTarget.value = 'Update';

      // Look for other facilitators in the selected time range, when editing
      this.#getAvailableFacilitators();
    }

    // Show a link button to view the scheduled session details
    if (this.editingSchedule.raw.showUrl) {
      this.viewLinkTarget.href = this.editingSchedule.raw.showUrl;
      this.viewLinkTarget.classList.remove('d-none');
    }

    if (!this.editingSchedule.raw.canDelete) {
      // Can't delete schedule - not permitted, hide the button
      this.#setElementEnabled(this.deleteButtonTarget, false);
      this.#setElementVisible(this.deleteButtonTarget, false);

    } else {
      // Can delete, so setup button
      this.deleteButtonTarget.classList.remove('btn-dark');
      this.deleteButtonTarget.classList.add('btn-danger');
      this.deleteButtonTarget.innerText = 'Cancel this session';
    }

    // Jump to final step, as we're editing
    this.#changeStep('participant_max');

    // Show the modal now that all is setup!
    $(this.element).modal('show');
  }

  #showForCreating() {
    if (!this.creatingSchedule) {
      throw new Error('There is no schedule intent to create.');
    }

    this.#setupHeader();

    this.currentStartTime = this.creatingSchedule.start.toDate();
    this.currentEndTime = this.creatingSchedule.end.toDate();

    this.dateTarget.valueAsDate = this.#getDate();
    this.startTimeTarget.valueAsDate = this.#getStartTime().utc(true).toDate();
    this.endTimeTarget.valueAsDate = this.#getEndTime().utc(true).toDate();

    this.submitButtonTarget.classList.remove('btn-secondary');
    this.submitButtonTarget.classList.add('btn-primary');
    this.submitButtonTarget.value = 'Schedule';

    this.deleteButtonTarget.classList.remove('btn-danger');
    this.deleteButtonTarget.classList.add('btn-dark');
    this.deleteButtonTarget.innerText = 'Discard';


    // Start by asking for the session content if session content is not already selected
    // otherwise ask for the time range
    if (!this.#isSessionContentValid()) {
      // Session content is not set, so ask for it
      this.#changeStep('session_content');
    } else {
      // Session content is already set, move to time range
      this.onSessionContent();
    }

    // Show the modal now that all is setup!
    $(this.element).modal('show');
  }

  /**
   * Whenever the session content is changed
   */
  onSessionContent() {
    // Validate:
    if (!this.#isSessionContentValid()) {
      this.#changeStep('session_content');
      this.#checkIfCanSubmit();
      return;
    }

    // Move to the next step;
    if (!this.startTimeTarget.valueAsDate || !this.endTimeTarget.valueAsDate) {
      // Both times are not set, so ask for them
      this.#changeStep('time_range');

    } else {
      // Both times are set. Validate times and check for facilitators
      this.onTime();
    }

    this.#checkIfCanSubmit();
  }

  /**
   * Handle any of start and times changes
   */
  onTime() {
    this.currentStartTime = moment(`${this.dateTarget.value} ${this.startTimeTarget.value}`, 'YYYY-MM-DD HH:mm');
    this.currentEndTime = moment(`${this.dateTarget.value} ${this.endTimeTarget.value}`, 'YYYY-MM-DD HH:mm');

    if (!this.#isTimeRangeValid()) {
      this.#changeStep('time_range');
      this.#checkIfCanSubmit();
      return;
    }

    this.#changeStep('facilitator');

    this.#setFacilitatorLoading(true);
    this.#getAvailableFacilitators();
  }

  /**
   * Handle facilitator changes
   */
  onFacilitator() {
    // Validate
    if (!this.#isFacilitatorValid()) {
      this.#changeStep('facilitator');
      this.#checkIfCanSubmit();
      return;
    }

    // Proceed on setting the number of maximum participants
    this.#changeStep('participant_max');

    this.#checkIfCanSubmit();
  }

  /**
   * Handle participant max changes
   */
  onParticipantMax() {
    // Validate
    this.#isParticipantMaxValid();

    this.#checkIfCanSubmit();
  }

  #getAvailableFacilitators() {
    // Cancel any previous ongoing facilitator fetch, to start a new one
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = new AbortController();
    }

    // Clear previous options
    this.facilitatorTarget.innerHTML = '';

    const startTime = this.#getStartTime().toISOString();
    const endTime = this.#getEndTime().toISOString();

    // Load facilitators available between the selected time range
    const { urlPath } = this.facilitatorTarget.dataset;
    let url = `${urlPath}?start_time=${startTime}&end_time=${endTime}`;

    if (this.isEditing) {
      url = `${url}&original_staff_user_id=${this.editingSchedule.raw.facilitatorId}`
    }

    fetch(url, { signal: this.abortController.signal })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Failed to get 200 Response while fetching facilitators: ${res}`);
      })
      .then(res => {
        const availableFacilitators = res.data;

        // Custom validity
        if (!availableFacilitators.length) {
          if (!this.#hasTimeRangeChanged()) {
            // If we're editing and there is no other faciliators available,
            // and we have the same times selected, then show the original editing scheduled facilitator.
            this.facilitatorTarget.value = this.editingSchedule.raw.facilitatorId;
            const preselectedOption = document.createElement('option');
            preselectedOption.value = this.editingSchedule.raw.facilitatorId;
            preselectedOption.text = this.editingSchedule.raw.facilitatorName;
            preselectedOption.selected = true;
            this.facilitatorTarget.value = preselectedOption.value;
            this.facilitatorTarget.appendChild(preselectedOption);
            this.#resetInputValidity(this.facilitatorTarget, this.facilitatorMessageTarget);
          }

          return;
        }

        const emptyOption = document.createElement('option');
        emptyOption.value = '';
        emptyOption.text = '';
        if (!this.isEditing) {
          // When creating, pre-select the empty option by default
          emptyOption.selected = true;
          this.facilitatorTarget.value = '';
        }
        this.facilitatorTarget.appendChild(emptyOption);

        // Populate the options with the available facilitator
        availableFacilitators.forEach(facilitator => {
          const { id, name } = facilitator;
          const option = document.createElement('option');
          option.value = id;
          option.text = name;
          this.facilitatorTarget.appendChild(option);
        });

        // If editing, check to see if we have the original faciliator in the list
        // Select it and add the faciliator if not present
        if (this.isEditing && this.editingSchedule) {
          let shouldAppend = true;
          for (const option of this.facilitatorTarget.options) {
            if (parseInt(option.value, 10) === parseInt(this.editingSchedule.raw.facilitatorId, 10)) {
              option.selected = true;
              this.facilitatorTarget.value = option.value;

              shouldAppend = false;
              break;
            }
          }

          if (shouldAppend) {
            // Otherwise, add the facilitator to the list, and pre-select
            const preselectedOption = document.createElement('option');
            preselectedOption.value = this.editingSchedule.raw.facilitatorId;
            preselectedOption.text = this.editingSchedule.raw.facilitatorName;
            preselectedOption.selected = true;
            this.facilitatorTarget.value = preselectedOption.value;
            this.facilitatorTarget.appendChild(preselectedOption);
          }
        }

        this.#setElementEnabled(this.facilitatorTarget, true);
      })
      .catch((err) => {
        if(err.name === "AbortError") {
          // We know it's been canceled!
          return;
        }

        console.error(err);
        alert('Something went wrong');
      })
      .finally(() => {
        this.#setFacilitatorLoading(false);
        this.#checkIfCanSubmit();
      });
  }

  /**
   * On any input value change
   */
  #checkIfCanSubmit() {
    const canSubmitForm = this.#canSubmitForm();
    this.#setElementEnabled(this.submitButtonTarget, canSubmitForm);
  }

  #isTimeRangeValid() {
    const updateValidity = !this.startTimeTarget.hasAttribute('disabled') || !this.endTimeTarget.hasAttribute('disabled');

    // If we're editing the schedule and time range hasn't changed
    // then assume valid
    if (!this.#hasTimeRangeChanged()) {
      this.#resetInputValidity(this.startTimeTarget, this.timesMessageTarget);
      this.#resetInputValidity(this.endTimeTarget, this.timesMessageTarget);
      return true;
    }

    // Time range must be valid, that is, start time in the past and end time in the future
    const startTime = this.#getStartTime();
    const endTime = this.#getEndTime();

    if (!startTime.isValid() || !endTime.isValid() || !endTime.isAfter(startTime)) {
      if (updateValidity) {
        this.#setInputValidity(this.startTimeTarget, false);
        this.#setInputValidity(this.endTimeTarget, false, this.timesMessageTarget, 'Please ensure that the selected start time is in the past for the selected end time.');
      }
      return false;
    }

    if (updateValidity) {
      this.#setInputValidity(this.startTimeTarget, true);
      this.#setInputValidity(this.endTimeTarget, true, this.timesMessageTarget);
    }
    return true;
  }

  #hasTimeRangeChanged() {
    // When editing, has the start / end times changed?
    if (this.isEditing && this.editingSchedule && this.editingSchedule.start) {
      const originalStart = moment(this.editingSchedule.start.toDate());
      const originalEnd = moment(this.editingSchedule.end.toDate());

      const currentStart = this.#getStartTime();
      const currentEnd = this.#getEndTime();

      if (originalStart.isSame(currentStart) && originalEnd.isSame(currentEnd)) {
        // No, no changes
        return false;
      }
    }

    // Yes, either we're not in edit mode, or it hasn't changed!
    return true;
  }

  #isSessionContentValid() {
    // When the session content input is disabled, force NO validity decorations on it.
    const updateValidity = !this.sessionContentTarget.hasAttribute('disabled');

    // If we're editing the schedule and the session content hasn't changed
    // then assume valid
    if (!this.#hasSessionContentChanged()) {
      this.#resetInputValidity(this.sessionContentTarget, this.sessionContentMessageTarget);
      return true;
    }

    // There must be a session content selected
    const sessionContentId = this.sessionContentTarget.value;
    if (!sessionContentId || Number.isNaN(sessionContentId)) {
      if (updateValidity) {
        this.#setInputValidity(this.sessionContentTarget, false, this.sessionContentMessageTarget, 'Please select a session content');
      }
      return false;
    }

    if (updateValidity) {
      this.#setInputValidity(this.sessionContentTarget, true, this.sessionContentMessageTarget);
    }
    return true;
  }

  #hasSessionContentChanged() {
    if (this.isEditing && this.editingSchedule && this.editingSchedule.raw) {
      const currentSessionContentId = parseInt(this.sessionContentTarget.value, 10);
      const originalSessionContentId = parseInt(this.editingSchedule.raw.sessionContentId, 10);

      if (currentSessionContentId === originalSessionContentId) {
        return false;
      }
    }

    return true;
  }

  #isFacilitatorValid() {
    // When the facilitator input is disabled, force NO validity decorations on it.
    const updateValidity = !this.facilitatorTarget.hasAttribute('disabled');

    // If we're editing the schedule and the facilitator hasn't changed
    // then assume valid
    if (!this.#hasFacilitatorChanged()) {
      this.#resetInputValidity(this.facilitatorTarget, this.facilitatorMessageTarget);
      return true;
    }

    // A custom error when there is no facilitators to select
    const hasFacilitatorsToSelect = this.facilitatorTarget.options.length > 0;
    if (!hasFacilitatorsToSelect) {
      if (updateValidity) {
        this.#setInputValidity(
          this.facilitatorTarget,
          false,
          this.facilitatorMessageTarget,
          'Sorry, there is currently no coach available for the selected time range. Please try a different date or time.'
        );
        this.#setElementEnabled(this.facilitatorTarget, false);
      }
      return;
    }

    const facilitatorId = parseInt(this.facilitatorTarget.value, 10);
    if (!facilitatorId || Number.isNaN(facilitatorId)) {
      if (updateValidity) {
        this.#setInputValidity(this.facilitatorTarget, false, this.facilitatorMessageTarget, 'Please select a coach');
      }
      return false;
    }

    if (updateValidity) {
      this.#setInputValidity(this.facilitatorTarget, true, this.facilitatorMessageTarget);
    }
    return true;
  }

  #hasFacilitatorChanged() {
    if (this.isEditing && this.editingSchedule && this.editingSchedule.raw) {
      const currentFacilitatorId = parseInt(this.facilitatorTarget.value, 10);
      const originalFacilitatorId = parseInt(this.editingSchedule.raw.facilitatorId, 10);

      if (currentFacilitatorId === originalFacilitatorId) {
        return false;
      }
    }

    return true;
  }

  #isParticipantMaxValid() {
    // When the facilitator input is disabled, force NO validity decorations on it.
    const updateValidity = !this.participantMaxTarget.hasAttribute('disabled');

    // If we're editing the schedule and the number of maximum participants hasn't changed
    // then assume valid
    if (!this.#hasParticipantMaxChanged()) {
      this.#resetInputValidity(this.participantMaxTarget, this.participantMaxMessageTarget);
      return true;
    }

    // The maximum number of participant must be between 0 and 15
    const participantMaxNumber = this.participantMaxTarget.valueAsNumber;
    if (Number.isNaN(participantMaxNumber) || participantMaxNumber <= 0 || participantMaxNumber > 15) {
      if (updateValidity) {
        this.#setInputValidity(this.participantMaxTarget, false, this.participantMaxMessageTarget, 'The number of maximum participants must be between 1 and 15');
      }
      return false;
    }

    if (updateValidity) {
      this.#setInputValidity(this.participantMaxTarget, true, this.participantMaxMessageTarget);
    }
    return true;
  }

  #hasParticipantMaxChanged() {
    if (this.isEditing && this.editingSchedule && this.editingSchedule.raw) {
      const currentParticipantMax = parseInt(this.participantMaxTarget.value, 10);
      const originalParticipantMax = parseInt(this.editingSchedule.raw.participantMax, 10);

      if (currentParticipantMax === originalParticipantMax) {
        return false;
      }
    }

    return true;
  }

  /**
   * Validates the entered form
   *
   * @returns `true` if valid, otherwise `false`
   */
  #canSubmitForm() {
    // Can't submit the form if editing and no values has changed
    if (
      !this.#hasSessionContentChanged() &&
      !this.#hasTimeRangeChanged() &&
      !this.#hasFacilitatorChanged() &&
      !this.#hasParticipantMaxChanged()
    ) {
      return false;
    }

    // Can submit the form if changed values are valid
    if (!this.#isSessionContentValid()) {
      return false;
    }

    if (!this.#isTimeRangeValid()) {
      return false;
    }

    if (!this.#isFacilitatorValid()) {
      return false;
    }

    if (!this.#isParticipantMaxValid()) {
      return false;
    }

    // All good, it seems.
    return true;
  }

  /**
   * Changes the form to the selected {@link step}
   * @param {('session_content' | 'time_range' | 'facilitator' | 'participant_max')} step
   */
  #changeStep(step) {
    this.step = step;

    this.#setElementVisible(this.sessionContentSectionTarget);
    this.#setElementVisible(this.timesSectionTarget);
    this.#setElementVisible(this.facilitatorSectionTarget);
    this.#setElementVisible(this.participantSectionTarget);

    this.#setElementVisible(this.busyFacilitatorTarget, false);

    if (this.isEditing) {
      return;
    }

    switch (this.step) {
      case 'session_content':
        this.#setElementVisible(this.timesSectionTarget, false);
        this.#setElementVisible(this.facilitatorSectionTarget, false);
        this.#setElementVisible(this.participantSectionTarget, false);
        break;

      case 'time_range':
        this.#setElementVisible(this.facilitatorSectionTarget, false);
        this.#setElementVisible(this.participantSectionTarget, false);
        break;

      case 'facilitator':
        this.#setElementVisible(this.participantSectionTarget, false);
        break;

      case 'participant_max':
        break;

      default:
        this.#setElementVisible(this.sessionContentSectionTarget, false);
        this.#setElementVisible(this.timesSectionTarget, false);
        this.#setElementVisible(this.facilitatorSectionTarget, false);
        this.#setElementVisible(this.participantSectionTarget, false);
        console.error('Invalid step: ', step);
        alert('Something went wrong');
        break;
    }
  }

  #reset() {
    // Clear all values
    this.editingSchedule = null;
    this.creatingSchedule = null;

    this.startTimeTarget.value = '';
    this.endTimeTarget.value = '';

    if (!this.sessionContentTarget.hasAttribute('disabled')) {
      this.sessionContentTarget.value = '';
    }

    if (!this.facilitatorTarget.hasAttribute('disabled')) {
      this.facilitatorTarget.value = '';
      this.facilitatorTarget.innerHTML = '';
    }

    this.participantMaxTarget.value = '';

    this.deleteButtonTarget.value = '';
    this.viewLinkTarget.href = '#';
    this.submitButtonTarget.value = '';

    this.#setElementVisible(this.viewLinkTarget, false);
    this.#resetInputValidity(this.sessionContentTarget, this.sessionContentMessageTarget);
    this.#resetInputValidity(this.startTimeTarget, this.timesMessageTarget);
    this.#resetInputValidity(this.endTimeTarget, this.timesMessageTarget);
    this.#resetInputValidity(this.facilitatorTarget, this.facilitatorMessageTarget);
    this.#resetInputValidity(this.participantMaxTarget, this.participantMaxMessageTarget);
  }

  #closeModal() {
    $(this.element).modal('hide');
  }

  #getDate() {
    return this.#getStartTime().utc(true).startOf('day').toDate();
  }

  #getStartTime() {
    return moment(this.currentStartTime);
  }

  #getEndTime() {
    return moment(this.currentEndTime);
  }

  #setupHeader() {
    if (this.isEditing) {
      // Header when editing an existing schedule
      if (this.editingSchedule.raw.showUrl) {
        const linkToEvent = document.createElement('a');
        linkToEvent.href = this.editingSchedule.raw.showUrl;
        linkToEvent.rel = 'noopenner';
        linkToEvent.target = '_blank';
        linkToEvent.innerText = this.editingSchedule.title;
        this.headerTarget.innerHTML = linkToEvent.outerHTML;

      } else {
        this.headerTarget.innerHTML = this.editingSchedule.title;
      }

    } else {
      // Header when creating a new schedule
      this.headerTarget.innerText = 'Schedule a new connected session';
    }
  }

  #resetInputValidity(inputElement, messageElement) {
    inputElement.classList.remove('is-valid', 'is-invalid');

    if (messageElement) {
      this.#setElementVisible(messageElement, false);
      messageElement.innerText = '';
    }
  }

  #setInputValidity(inputElement, isValid, messageElement, message) {
    if (isValid) {
      inputElement.classList.add('is-valid');
      inputElement.classList.remove('is-invalid');
    } else {
      inputElement.classList.add('is-invalid');
      inputElement.classList.remove('is-valid');
    }

    if (!messageElement) {
      return;
    }

    if (!isValid && message) {
      this.#setElementVisible(messageElement);
      messageElement.innerText = message;

    } else {
      this.#setElementVisible(messageElement, false);
      messageElement.innerText = '';
    }
  }

  #setFacilitatorLoading(isLoading = true) {
    if (isLoading) {
      // Disable the input, hide the message element underneath and show that its loading
      this.#setElementEnabled(this.facilitatorTarget, false);
      this.#setElementVisible(this.facilitatorMessageTarget, false);
      this.#setElementVisible(this.busyFacilitatorTarget);

    } else {
      this.#setElementVisible(this.busyFacilitatorTarget, false);
      this.#setElementEnabled(this.facilitatorTarget);
      this.#setElementVisible(this.facilitatorMessageTarget);
    }
  }

  #setElementVisible(element, isVisible = true) {
    element.classList.toggle('d-none', !isVisible);
    element.classList.toggle('d-block', isVisible);
  }

  #setElementEnabled(element, isEnabled = true) {
    element.toggleAttribute('disabled', !isEnabled);
  }
}
