import CalendarController from './calendar_controller';

/**
 * Admin user controlling calendar for working hours schedules Facilitator user's
 */
export default class extends CalendarController {
  connect() {
    const facilitatorId = this.data.get('facilitatorId');

    fetch(`/admin/staff_users/${facilitatorId}/working_hours.json`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch the calendar data for the current facilitator.');
      }
    })
    .then(() => {
      // URLs
      this.setGetIndexURL(`/admin/staff_users/${facilitatorId}/working_hours.json`);
      this.setCreateURL(`/admin/staff_users/${facilitatorId}/working_hours`);
      this.setDefaultCalendarId('StaffWorkingHour');

      // Data control.
      this.getCalendarData();
      this.setupUpdateCalendarEvent();
      this.setupDeleteCalendarEvent();
      this.setupCreateCalendarEvent();
    })
    .catch(error => {
      alert(`${error}`);
    });

    // Calendar init and buttons.
    this.initCalendar();
    this.setupOnPrev();
    this.setupOnNext();
    this.setupOnToday();
    this.setupCalendarViews();

    // Handle refreshing the page.
    this.calendar.changeView(this.viewSelection.value, true);
    this.updateDateHeaders();
  }

  /* -------------------------------------------------------------------------- */
  /*                                  Overrides                                 */
  /* -------------------------------------------------------------------------- */

  setupCreateCalendarEvent() {
    this.calendar.on('beforeCreateSchedule', (creatingSchedule) => {
      this.#onCreate(this.calendar, creatingSchedule);
    });
  }

  #onCreate(calendar, creatingSchedule) {
    this.dispatch('clickScheduleStaffWorkingHour', { detail: { calendar, creatingSchedule } })
  }
}
