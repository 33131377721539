import { Controller } from 'stimulus'
import Rails from '@rails/ujs';
import moment from 'moment';

export default class extends Controller {
  static targets = [
    'header',
    'date',

    'startTime',
    'endTime',
    'timesMessage',

    'deleteButton',
    'submitButton'
  ]

  connect() {
    this.element.addEventListener('hidden.bs.modal', () => {
      // On modal hide
      if (this.creatingSchedule) {
        this.creatingSchedule.guide.clearGuideElement();
      }

      this.#reset();
    });
  }

  onDelete(event) {
    event.preventDefault();

    if (this.isEditing && this.editingSchedule) {
      if (!this.editingSchedule.raw.canDelete) {
        alert("You can't delete working hours");
        this.#closeModal();
        return;
      }

      // Delete actual schedule
      this.dispatch('delete', { detail: { schedule: this.editingSchedule } });
    }

    this.#closeModal();
  }

  onSubmit(event) {
    event.preventDefault();

    if (!this.isEditing) {
      // Create
      const { createUrlPath } = this.element.dataset;

      const startTime = this.#getStartTime().toISOString();
      const endTime = this.#getEndTime().toISOString();
      const schedule = {
        calendarId: this.calendarId,
        title: 'Staff working hours',
        category: 'time',
        start: startTime,
        end: endTime,
        raw: {
          historyStart: [startTime],
          historyEnd: [endTime]
        }
      }

      const data = new FormData();
      data.append('[staff_working_hour]start_time', startTime);
      data.append('[staff_working_hour]end_time', endTime);

      Rails.ajax({
        type: 'POST',
        url: createUrlPath,
        data: data,
        success: (res) => {
          const createdSchedule = res.data;

          schedule.id = createdSchedule.id;
          schedule.calendarId = createdSchedule.attributes['calendar-id'];
          schedule.title = createdSchedule.attributes['event-title'];
          schedule.raw.className = createdSchedule.attributes['class-name'];
          schedule.raw.updateUrl = createdSchedule.attributes['update-url'];
          schedule.raw.showUrl = createdSchedule.attributes['show-url'];
          schedule.raw.formModel = createdSchedule.attributes['form-model'];
          schedule.raw.canUpdate = createdSchedule.attributes['can-update'];
          schedule.raw.canDelete = createdSchedule.attributes['can-delete'];
          schedule.raw.sessionContentId = createdSchedule.attributes['session-content-id'];
          schedule.raw.facilitatorId = createdSchedule.attributes['facilitator-id'];
          schedule.raw.facilitatorName = createdSchedule.attributes['facilitator-name'];
          schedule.raw.participantMax = createdSchedule.attributes['participant-max'];

          if (this.calendar) {
            this.calendar.createSchedules([schedule]);
          }

          this.dispatch('flash', { detail: { message: 'The calendar event has been successfully created.' } });
        },
        error: (err) => {
          console.error(err);
          this.dispatch('flash', { detail: { message: 'Something went wrong while creating your working hour. Please try again and if the problem persists, contact the team' } });
        }
      });

    } else if (this.isEditing && this.editingSchedule) {
      if (!this.editingSchedule.raw.canUpdate) {
        alert("You can't update your working hours");
        this.#closeModal();
        return;
      }

      // Update
      const changes = {};
      if (this.#hasTimeRangeChanged()) {
        changes.start = this.#getStartTime().toISOString();
        changes.end = this.#getEndTime().toISOString();
      }

      const eventArgs = {
        schedule: this.editingSchedule,
        changes: changes
      }
      this.dispatch('update', { detail: { event: eventArgs } });
    }

    // Dismiss the modal
    this.#closeModal();
  }

  show({ detail }) {
    this.calendar = detail.calendar;

    if (detail.schedule) {
      // Editing an existing schedule
      this.isEditing = true;
      this.editingSchedule = detail.schedule;

      this.#showForEditing();

    } else if (detail.creatingSchedule) {
      // Creating a new schedule
      this.isEditing = false;
      this.creatingSchedule = detail.creatingSchedule;

      this.#showForCreating();
    }
  }

  #showForEditing() {
    if (!this.editingSchedule) {
      throw new Error('There is no schedule to edit.');
    }

    this.#setupHeader();

    this.currentStartTime = this.editingSchedule.start.toDate();
    this.currentEndTime = this.editingSchedule.end.toDate();

    this.dateTarget.valueAsDate = this.#getDate();
    this.startTimeTarget.valueAsDate = this.#getStartTime().utc(true).toDate();
    this.endTimeTarget.valueAsDate = this.#getEndTime().utc(true).toDate();

    if (!this.editingSchedule.raw.canUpdate) {
      // Can't update schedule details - not permitted, hide submit button and disable all other input controls
      this.#setElementVisible(this.submitButtonTarget, false);
      this.#setElementEnabled(this.submitButtonTarget, false);

      this.#setElementEnabled(this.startTimeTarget, false);
      this.#setElementEnabled(this.endTimeTarget, false);

    } else {
      // Can submit, so setup the button
      this.submitButtonTarget.classList.remove('btn-primary');
      this.submitButtonTarget.classList.add('btn-secondary');
      this.submitButtonTarget.value = 'Update';
    }

    if (!this.editingSchedule.raw.canDelete) {
      // Can't delete this scshedule - not permitted, hide delete button.
      this.#setElementVisible(this.deleteButtonTarget, false);
      this.#setElementEnabled(this.deleteButtonTarget, false);
    }

    // Show the modal now that all is setup!
    $(this.element).modal('show');
  }

  #showForCreating() {
    if (!this.creatingSchedule) {
      throw new Error('There is no schedule intent to create.');
    }

    this.#setupHeader();

    this.currentStartTime = this.creatingSchedule.start.toDate();
    this.currentEndTime = this.creatingSchedule.end.toDate();

    this.dateTarget.valueAsDate = this.#getDate();
    this.startTimeTarget.valueAsDate = this.#getStartTime().utc(true).toDate();
    this.endTimeTarget.valueAsDate = this.#getEndTime().utc(true).toDate();

    this.submitButtonTarget.classList.remove('btn-secondary');
    this.submitButtonTarget.classList.add('btn-primary');
    this.submitButtonTarget.value = 'Add';

    this.deleteButtonTarget.classList.remove('btn-danger');
    this.deleteButtonTarget.classList.add('btn-dark');
    this.deleteButtonTarget.innerText = 'Discard';

    // Show the modal now that all is setup!
    $(this.element).modal('show');
  }

  #getDate() {
    return this.#getStartTime().utc(true).startOf('day').toDate();
  }

  #getStartTime() {
    return moment(this.currentStartTime);
  }

  #getEndTime() {
    return moment(this.currentEndTime);
  }

  #closeModal() {
    $(this.element).modal('hide')
  }

  /**
   * Validates the entered form
   *
   * @returns `true` if valid, otherwise `false`
   */
  #canSubmitForm() {
    // Can't submit the form if editing and no values has changed
    if (!this.#hasTimeRangeChanged()) {
      return false;
    }

    // Can submit the form if changed values are valid
    if (!this.#isTimeRangeValid()) {
      return false;
    }

    // All good, it seems.
    return true;
  }

  /**
   * Whenever start / end times are changed
   */
  onTime() {
    this.currentStartTime = moment(`${this.dateTarget.value} ${this.startTimeTarget.value}`, 'YYYY-MM-DD HH:mm');
    this.currentEndTime = moment(`${this.dateTarget.value} ${this.endTimeTarget.value}`, 'YYYY-MM-DD HH:mm');

    // Validate
    if (!this.#isTimeRangeValid()) {
      this.#checkIfCanSubmit();
      return;
    }

    this.#checkIfCanSubmit();
  }

  /**
   * On any input value change
   */
  #checkIfCanSubmit() {
    const canSubmitForm = this.#canSubmitForm();
    this.#setElementEnabled(this.submitButtonTarget, canSubmitForm);
  }

  #isTimeRangeValid() {
    const updateValidity = !this.startTimeTarget.hasAttribute('disabled') || !this.endTimeTarget.hasAttribute('disabled');

    // If we're editing the schedule and time range hasn't changed
    // then assume valid
    if (!this.#hasTimeRangeChanged()) {
      this.#resetInputValidity(this.startTimeTarget, this.timesMessageTarget);
      this.#resetInputValidity(this.endTimeTarget, this.timesMessageTarget);
      return true;
    }

    if (updateValidity) {
      this.#setInputValidity(this.startTimeTarget, true);
      this.#setInputValidity(this.endTimeTarget, true, this.timesMessageTarget);
    }
    return true;
  }

  #hasTimeRangeChanged() {
    // When editing, has the start / end times changed?
    if (this.isEditing && this.editingSchedule) {
      const originalStart = moment(this.editingSchedule.start.toDate());
      const originalEnd = moment(this.editingSchedule.end.toDate());

      const currentStart = this.#getStartTime();
      const currentEnd = this.#getEndTime();

      if (originalStart.isSame(currentStart) && originalEnd.isSame(currentEnd)) {
        // No, no changes
        return false;
      }
    }

    // Yes, either we're not in edit mode, or it hasn't changed!
    return true;
  }

  #setupHeader() {
    if (this.isEditing) {
      // Header when editing an existing schedule
      if (this.editingSchedule.raw.showUrl) {
        const linkToEvent = document.createElement('a');
        linkToEvent.href = this.editingSchedule.raw.showUrl;
        linkToEvent.rel = 'noopenner';
        linkToEvent.target = '_blank';
        linkToEvent.innerText = this.editingSchedule.title;
        this.headerTarget.innerHTML = linkToEvent.outerHTML;
        return;

      } else {
        this.headerTarget.innerHTML = this.editingSchedule.title;
      }


    } else {
      // Header when creating a new schedule
      this.headerTarget.innerText = 'Add new working hours';
    }
  }

  #reset() {
    // Clear all values
    this.editingSchedule = null;
    this.creatingSchedule = null;

    this.startTimeTarget.value = '';
    this.endTimeTarget.value = '';

    this.deleteButtonTarget.value = '';
    this.submitButtonTarget.value = '';

    this.#resetInputValidity(this.startTimeTarget, this.timesMessageTarget);
    this.#resetInputValidity(this.endTimeTarget, this.timesMessageTarget);
  }


  #setInputValidity(inputElement, isValid, messageElement, message) {
    if (isValid) {
      inputElement.classList.add('is-valid');
      inputElement.classList.remove('is-invalid');
    } else {
      inputElement.classList.add('is-invalid');
      inputElement.classList.remove('is-valid');
    }

    if (!messageElement) {
      return;
    }

    if (!isValid && message) {
      this.#setElementVisible(messageElement);
      messageElement.innerText = message;

    } else {
      this.#setElementVisible(messageElement, false);
      messageElement.innerText = '';
    }
  }

  #resetInputValidity(inputElement, messageElement) {
    inputElement.classList.remove('is-valid', 'is-invalid');

    if (messageElement) {
      this.#setElementVisible(messageElement, false);
      messageElement.innerText = '';
    }
  }

  #setElementVisible(element, isVisible = true) {
    element.classList.toggle('d-none', !isVisible);
    element.classList.toggle('d-block', isVisible);
  }

  #setElementEnabled(element, isEnabled = true) {
    element.toggleAttribute('disabled', !isEnabled);
  }
}
