import CalendarController from './calendar_controller';

export default class extends CalendarController {
  connect() {
    const facilitatorId = this.data.get('facilitatorId');

    fetch(`/calendar/staff_users/${facilitatorId}/events.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch the calendar data for the current facilitator.');
        }
      })
      .then(() => {
        // URLs
        this.setGetIndexURL(`/calendar/staff_users/${facilitatorId}/events.json`);
        this.setCreateURL('/facilitator/staff_working_hours');
        this.setDefaultCalendarId('StaffWorkingHour');

        // Data control.
        this.getCalendarData();

        this.setupUpdateCalendarEvent();
        this.setupDeleteCalendarEvent();
        this.setupCreateCalendarEvent();
      })
      .catch(error => {
        alert(error);
      });

    // Calendar init and buttons.
    this.initCalendar();
    this.setupOnPrev();
    this.setupOnNext();
    this.setupOnToday();
    this.setupCalendarViews();

    // Handle refreshing the page.
    this.calendar.changeView(this.viewSelection.value, true);
    this.updateDateHeaders();
  }

  /* -------------------------------------------------------------------------- */
  /*                                  Overrides                                 */
  /* -------------------------------------------------------------------------- */

  setupCreateCalendarEvent() {
    this.calendar.on('beforeCreateSchedule', (creatingSchedule) => {
      this.#createScheduleOverride(this.calendar, creatingSchedule);
    });
  }

  #createScheduleOverride(calendar, creatingSchedule) {
    this.dispatch('clickScheduleStaffWorkingHour', { detail: { calendar, creatingSchedule } })
  }
}
