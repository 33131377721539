import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'submitButton', 'msgDanger', 'msgInfo', 'msgWarning' ]

  #numberOfQuestions = 7

  connect() {
    this.#showOrHideMessages()
  }

  radioClick() {
    this.#showOrHideMessages()
  }

  #showOrHideMessages() {
    const radios = this.element.querySelectorAll('input[type=radio]:checked')
    const booleans = [...radios].map(i => i.value)
    if (booleans.length !== this.#numberOfQuestions) { return }

    this.#showElement(this.msgWarningTarget)
    this.submitButtonTarget.classList.remove('disabled')

    const uniq = [...new Set(booleans)]
    if (uniq.length === 1 && uniq[0] === 'false') {
      this.#showElement(this.msgInfoTarget)
      this.#hideElement(this.msgDangerTarget)
    } else { // At least one question is true
      this.#showElement(this.msgDangerTarget)
      this.#hideElement(this.msgInfoTarget)
    }
  }

  #showElement(element) {
    element.classList.remove('d-none')
  }

  #hideElement(element) {
    element.classList.add('d-none')
  }
}
