import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'remindMeOfYourOriginalGoal',
    'howAreYouGettingOn',
    'haveYouBeenAbleToRecheck',
    'howHaveYouFoundTheProcess',
    'howDoYouFeelAboutCompleting',
    'howAreYouWithEmotionalWellbeing',
    'coachesNotes',
  ]

  static values = {
    url: String
  }

  patch(event) {
    const controller = this

    const element = event.target
    const value = element.value
    const name = element.getAttribute('name')
    const formData = new FormData()
    formData.append(name, value)

    Rails.ajax({
      type: 'PATCH',
      url: this.urlValue,
      data: formData,
      success: () => controller.#setValid(element),
      error: () => controller.#setInvalid(element)
    })
  }

  copy({ params: { url } }) {
    const controller = this

    Rails.ajax({
      type: 'GET',
      url: url,
      success: (response) => {
        controller.#update(response.data.data.attributes)
        const inputs = controller.element.querySelectorAll('textarea')
        inputs.forEach(input => controller.#setValid(input))
      },
      error: () => {
        const inputs = controller.element.querySelectorAll('textarea')
        inputs.forEach(input => controller.#setInvalid(input))
      }
    })
  }

  #update(attributes) {
    this.remindMeOfYourOriginalGoalTarget.value = attributes['remind-me-of-your-original-goal']
    this.howAreYouGettingOnTarget.value = attributes['how-are-you-getting-on']
    this.haveYouBeenAbleToRecheckTarget.value = attributes['have-you-been-able-to-recheck']
    this.howHaveYouFoundTheProcessTarget.value = attributes['how-have-you-found-the-process']
    this.howDoYouFeelAboutCompletingTarget.value = attributes['how-do-you-feel-about-completing']
    this.howAreYouWithEmotionalWellbeingTarget.value = attributes['how-are-you-with-emotional-wellbeing']
    this.coachesNotesTarget.value = attributes['coaches-notes']

    const formData = new FormData()
    formData.append('review_session[remind_me_of_your_original_goal]', attributes['remind-me-of-your-original-goal'])
    formData.append('review_session[how_are_you_getting_on]', attributes['how-are-you-getting-on'])
    formData.append('review_session[have_you_been_able_to_recheck]', attributes['have-you-been-able-to-recheck'])
    formData.append('review_session[how_have_you_found_the_process]', attributes['how-have-you-found-the-process'])
    formData.append('review_session[how_do_you_feel_about_completing]', attributes['how-do-you-feel-about-completing'])
    formData.append('review_session[how_are_you_with_emotional_wellbeing]', attributes['how-are-you-with-emotional-wellbeing'])
    formData.append('review_session[coaches_notes]', attributes['coaches-notes'])

    Rails.ajax({
      type: 'PATCH',
      url: this.urlValue,
      data: formData
    })
  }

  #setValid(element) {
    element.classList.add('is-valid')
    element.classList.remove('is-invalid')
  }

  #setInvalid(element) {
    element.classList.remove('is-valid')
    element.classList.add('is-invalid')
  }
}
