import consumer from '../channels/consumer';
import {
  createLocalTracks,
  Room,
  RoomEvent,
  VideoPresets,
  ConnectionError,
  TrackInvalidError,
  NegotiationError,
  PublishDataError,
  UnsupportedServer,
  UnexpectedConnectionState, LocalParticipant,
  ScreenSharePresets, AudioPresets, VideoPreset
} from 'livekit-client';

const h320fps15 = new VideoPreset(320, 180, 125_000, 15);
const h640fps25 = new VideoPreset(640, 360, 400_000, 25);
const h720fps25 = new VideoPreset(1280, 720, 2_000_000, 25);
const trackDefaultOptions =  {
  audioBitrate: AudioPresets.music.maxBitrate,
  dtx: true,
  red: true,
  forceStereo: false,
  simulcast: true,
  videoSimulcastLayers: [h720fps25, h640fps25, h320fps15],
  screenShareSimulcastLayers: [h720fps25, h640fps25, h320fps15],
  screenShareEncoding: h720fps25.encoding,
  stopMicTrackOnMute: false,
  videoCodec: 'vp8',
  backupCodec: { codec: 'vp8', encoding: VideoPresets.h540.encoding },
}

async function joinVideoConferencing(liveKitUrl, token, facilitatorControls, liveKitFacilitatorControlsBaseUrl,
                                     liveKitLocalParticipantTarget, liveKitRemoteParticipantTarget,
                                     hasResponsiveParent, sessionUrl) {
  const room = new Room({
    adaptiveStream: true,     // automatically manage subscribed video quality
    dynacast: true,           // optimize publishing bandwidth and CPU for published tracks
    videoCaptureDefaults: {   // default capture settings
      resolution: VideoPresets.h720.resolution,
    },
  });

  // List for other participants
  room.on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
    console.log("Participant Subscribed");
    attachParticipantTrack(liveKitRemoteParticipantTarget, track, participant, facilitatorControls, liveKitFacilitatorControlsBaseUrl);
  }).on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakerChange)
    .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
    .on(RoomEvent.LocalTrackUnpublished, handleLocalTrackUnpublished)
    .on(RoomEvent.ParticipantDisconnected, handleParticipantDisconnected)
    .on(RoomEvent.TrackMuted, (track, participant) => {
      const videoElement = document.getElementById('video-conference-participant-' + participant.sid + '-' + track.source.replace('camera', 'video'));
      if (videoElement) {
        videoElement.style.opacity = 0;
      }
      refreshButtonLabels(room)
    })
    .on(RoomEvent.TrackUnmuted, (track, participant) => {
      const videoElement = document.getElementById('video-conference-participant-' + participant.sid + '-' + track.source.replace('camera', 'video'));
      if (videoElement) {
        videoElement.style.opacity = 1;
      }
      refreshButtonLabels(room)
    });

  // Connect to the room
  await room.connect(liveKitUrl, token);
  console.log('Connected to room: ', room.name);

  // Setup local tracks (audio and video)
  const tracks = await createLocalTracks({
    audio: {
      echoCancellation: true,
      noiseSuppression: true
    },
    video: {
      resolution: ScreenSharePresets.h1080fps30.resolution
    }
  });

  for (let track of tracks) {
    await room.localParticipant.publishTrack(track, trackDefaultOptions);

    if (track.kind === "video") {
      attachLocalTrack(liveKitLocalParticipantTarget, track, room.localParticipant, hasResponsiveParent);
    }
  }

  // Bind click events of buttons and set initial state
  setupCameraAndMicrophoneToggles(room, sessionUrl);

  // Listen for facilitator actions (mute mic, mute video, evict)
  registerForFacilitatorActions(room);
}

// When called this method will refresh the state of the microphone and video toggle buttons based on
// localParticipant state information
function refreshButtonLabels(room) {
  Array.from(document.getElementsByClassName('btn-microphone-toggle')).forEach((microphoneBtn) => {
    microphoneBtn.innerHTML = room.localParticipant.isMicrophoneEnabled ? 'Turn Microphone OFF' : 'Turn Microphone ON';
  });
  Array.from(document.getElementsByClassName('btn-camera-toggle')).forEach((cameraBtn) => {
    cameraBtn.innerHTML = room.localParticipant.isCameraEnabled ? 'Turn Camera OFF' : 'Turn Camera ON';
  });
}

function refreshStartEndSessionButtons(sessionUrl) {
  $.getJSON(sessionUrl, function(response) {
    const sessionOpen = response.data ? response.data.attributes['session-open'] : response.session_open;
    if (sessionOpen) {
      $('.btn-begin-session').addClass('d-none').removeClass('d-block');
      if ($('.btn-end-session').length > 0) {
        $('.btn-end-session').addClass('d-block').removeClass('d-none');
      }
    } else {
      $('.btn-begin-session').addClass('d-block').removeClass('d-none');
      if ($('.btn-end-session').length > 0) {
        $('.btn-end-session').addClass('d-none').removeClass('d-block');
      }
    }
  });
}

// Setup click events for toggle buttons
function setupCameraAndMicrophoneToggles(room, sessionUrl) {
  Array.from(document.getElementsByClassName('btn-microphone-toggle')).forEach((microphoneBtn) => {
    microphoneBtn.addEventListener("click", function () {
      const switchStatus = !room.localParticipant.isMicrophoneEnabled;
      room.localParticipant.setMicrophoneEnabled(switchStatus);
      refreshButtonLabels(room);
    });
  });

  Array.from(document.getElementsByClassName('btn-camera-toggle')).forEach((cameraBtn) => {
    cameraBtn.addEventListener("click", function () {
      const switchStatus = !room.localParticipant.isCameraEnabled;
      room.localParticipant.setCameraEnabled(switchStatus);
      refreshButtonLabels(room);
    });
  });

  Array.from(document.getElementsByClassName('btn-device-settings')).forEach((deviceSettingsBtn) => {
    deviceSettingsBtn.addEventListener("click", function () {
      const appDialogEl = document.querySelector('#select-device-dialog');
      const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
      dialog.show();
      $("#app-select-device-dialog-select-camera").empty();
      Room.getLocalDevices('videoinput').then(devices => {
        devices.forEach((device) => {
          $('<option/>', { value : device.deviceId})
            .text(device.label)
            .appendTo('#app-select-device-dialog-select-camera');
        });
        $("#app-select-device-dialog-select-camera").val($("#app-select-device-dialog-select-camera option:first").val());
      });
      $(".app-select-device-dialog-apply").off("click").click(function() {
        const deviceId = $('#app-select-device-dialog-select-camera').find(":selected").val();
        room.switchActiveDevice('videoinput', deviceId).then(() => {
          dialog.hide();
        })
      })
    });
  });

  Array.from(document.getElementsByClassName('btn-end-session')).forEach((endSessionBtn) => {
    endSessionBtn.addEventListener("click", function () {
      const appDialogEl = document.querySelector('#end-session-dialog');
      const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
      dialog.show();
      $(".app-end-session-dialog-confirm").off("click").click(function() {
        $.ajax({
          url: sessionUrl,
          type: 'DELETE',
          success: function(response) {
            room.disconnect();
            dialog.hide();
            window.location = '/';
          }
        });
      })
    });
  });

  Array.from(document.getElementsByClassName('btn-begin-session')).forEach((beginSessionBtn) => {
    const sessionType = $(beginSessionBtn).data('session-type');
    const data = {};
    data[sessionType] = {
      session_open: true
    }
    beginSessionBtn.addEventListener("click", function () {
      $.ajax({
        url: sessionUrl,
        method: 'PUT',
        dataType: 'json',
        data: data,
        success: function(response) {
          refreshStartEndSessionButtons(sessionUrl);
        }
      });
    });
  });

  refreshStartEndSessionButtons(sessionUrl);

  Array.from(document.getElementsByClassName('btn-screenshare')).forEach((broadcastVideoBtn) => {
    broadcastVideoBtn.addEventListener("click", function () {

      // Video
      const video = document.getElementById('video-conference-screenshare-video') ? document.getElementById('video-conference-screenshare-video') : document.createElement("video");
      video.id = 'video-conference-screenshare-video';
      video.className = 'video-conference-video';

      // Container
      const videoContainer = document.createElement("div");
      videoContainer.id = 'video-conference-screenshare-video-container';
      videoContainer.className = 'video-conference-you-container';
      videoContainer.appendChild(video);

      room.localParticipant.createScreenTracks({
        audio: true,
        resolution: ScreenSharePresets.h1080fps30.resolution,
      }).then(tracks => {
        tracks.forEach((track) => {
          room.localParticipant.publishTrack(track, trackDefaultOptions);
          if (track.kind === "video") {
            track.attach(video);
          }
        });
        if (document.getElementById('livekit-screen-share-instructions')) {
          document.getElementById('livekit-screen-share-instructions').classList.add('d-none');
        }
        $('#livekit-screen-share-target').prepend(videoContainer);
        if (document.getElementById('livekit-screen-share-target')) {
          document.getElementById('livekit-screen-share-target').classList.remove('d-none');
        }
      });
    });
  });

  refreshButtonLabels(room);
}

// Listen for facilitator actions and respond accordingly
function registerForFacilitatorActions(room) {
  consumer.subscriptions.create(
    {channel: 'SessionControlsChannel', id: 1},
    {
      connected() {
        console.log('Connected to SessionControlsChannel');
      },
      disconnected() {
        console.log('Disconnected from SessionControlsChannel');
      },
      received(data) {
        console.log('Facilitator has triggered the action: ' + data.action);
        if (data.action === 'mute_microphone') {
          room.localParticipant.setMicrophoneEnabled(false);
        }
        if (data.action === 'mute_video') {
          room.localParticipant.setCameraEnabled(false);
        }
        if (data.action === 'evict') {
          window.location = '/';
        }
        if (data.action === 'session-ended') {
          room.disconnect();
          const appDialogEl = document.querySelector('#app-meeting-closed-dialog');
          const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
          dialog.show();
        }
      }
    });
}

function handleParticipantDisconnected(participant) {
  console.log(`Participant disconnected: ${participant.sid}.`)
  const participantContainerId = 'video-conference-participant-container' + participant.sid + '-camera';
  const videoContainer = document.getElementById(participantContainerId);
  if (videoContainer) {
    videoContainer.remove();
  }
}

// Highlight the speaker that is speaking
function handleActiveSpeakerChange(participants) {
  // Remove all prior speaking indicators
  Array.from(document.getElementsByClassName('video-conference-video')).forEach((video) => {
    video.classList.remove("speaking");
  });

  participants.forEach(participant => {
    const video = document.getElementById('video-conference-participant-' + participant.sid);
    if (video && participant.isSpeaking) {
      // Visually indicate that a speaking participant is speaking
      video.classList.add("speaking");
    }
  });
}

function handleLocalTrackUnpublished(track, publication, participant) {
  if (track.source === 'screen_share') {
    if (document.getElementById('livekit-screen-share-instructions')) {
      document.getElementById('livekit-screen-share-instructions').classList.remove('d-none');
    }
    if (document.getElementById('livekit-screen-share-target')) {
      document.getElementById('livekit-screen-share-target').classList.add('d-none');
    }
  }
}

function handleTrackUnsubscribed(track, publication, participant) {
  console.log('Detaching ' + track.kind + ' track from ' + track.source + ' for participant ' + participant.sid);
  track.detach();
  const participantContainerId = 'video-conference-participant-container' + participant.sid;
  const videoContainer = document.getElementById(participantContainerId);
  if (videoContainer) {
    videoContainer.remove();
  }
  if (track.source === 'screen_share') {
    document.getElementById('livekit-screen-share-instructions').classList.remove('d-none');
    document.getElementById('livekit-screen-share-target').classList.add('d-none');
  }
}

// Function for attaching a local track (video stream) to the page.
function attachLocalTrack(liveKitLocalParticipantTarget, track, participant, hasResponsiveParent) {
  console.log('Attaching track for local participant ' + participant.sid
    + ' to target ' + liveKitLocalParticipantTarget);

  // Video
  const video = document.createElement("video");
  video.id = 'video-conference-participant-' + participant.sid + '-video';
  video.className = 'video-conference-video';
  track.attach(video);

  // Container
  const videoContainer = document.createElement("div");
  videoContainer.id = 'video-conference-you-container';
  videoContainer.className = 'col video-conference-you-container';
  videoContainer.appendChild(video);
  let container = videoContainer;

  const videoFooter = document.createElement("div");
  videoFooter.className = 'video-footer d-flex align-items-center';

  // Name Element
  const nameElement = document.createElement("div");
  nameElement.className = 'video-footer-name';
  nameElement.innerText = participant.name;
  videoFooter.appendChild(nameElement);

  videoContainer.appendChild(videoFooter);

  // Parent container for responsiveness
  if (hasResponsiveParent) {
    const parentContainer = document.createElement("div");
    parentContainer.id = 'video-conference-you-container-parent';
    parentContainer.className = 'col';
    parentContainer.appendChild(videoContainer);
    container = parentContainer;
  }

  // Add Video to page
  $('#' + liveKitLocalParticipantTarget).prepend(container);
}

// Function for attaching a remote track (video stream) to the page.
function attachParticipantTrack(liveKitRemoteParticipantTarget, track, participant, facilitatorControls, liveKitFacilitatorControlsBaseUrl, hasResponsiveParent) {
  console.log('Attaching ' + track.kind + ' track from ' + track.source + ' for remote participant ' + participant.sid);

  const participantVideoId = 'video-conference-participant-' + participant.sid + '-' + track.source;
  const participantContainerId = 'video-conference-participant-container' + participant.sid + '-' + track.source;

  // Video / Audio attachment
  const video = document.getElementById(participantVideoId) ? document.getElementById(participantVideoId): document.createElement("video");
  video.id = participantVideoId;
  video.className = 'video-conference-video';
  track.attach(video);

  if (track.kind === "audio") {
    // Ignore audio tracks;
    return
  }

  // Mute Mic control
  const muteMic = document.createElement('div');
  muteMic.className = 'btn btn-sm btn-danger-soft btn-outline-danger';
  muteMic.title = 'Mute microphone';
  muteMic.addEventListener('click', function () {
    triggerParticipantAction(liveKitFacilitatorControlsBaseUrl, participant.identity, 'mute_microphone');
  });
  const muteMicIcon = document.createElement('i');
  muteMicIcon.className = 'fas fa-fw fa-microphone';
  muteMic.appendChild(muteMicIcon);

  // Mute Video control
  const muteVideo = document.createElement('div');
  muteVideo.className = 'btn btn-sm btn-danger-soft btn-outline-danger ms-2';
  muteVideo.title = 'Stop video';
  muteVideo.addEventListener('click', function () {
    triggerParticipantAction(liveKitFacilitatorControlsBaseUrl, participant.identity, 'mute_video');
  });
  const muteVideoIcon = document.createElement('i');
  muteVideoIcon.className = 'fas fa-fw fa-video';
  muteVideo.appendChild(muteVideoIcon);

  // Evict control
  const evict = document.createElement('div');
  evict.className = 'btn btn-sm btn-danger-soft btn-outline-danger ms-2';
  evict.title = 'Evict from the call';
  evict.addEventListener("click", function () {
    const appDialogEl = document.querySelector('#evict-participant-dialog');
    const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
    dialog.show();
    $(".app-evict-participant-dialog-evict").off("click").click(function() {
      dialog.hide();
      triggerParticipantAction(liveKitFacilitatorControlsBaseUrl, participant.identity, 'evict');;
    })
  });
  const evictIcon = document.createElement('i');
  evictIcon.className = 'fas fa-fw fa-door-closed';
  evict.appendChild(evictIcon);

  // Container
  const videoContainer = document.createElement("div");
  videoContainer.id = participantContainerId;

  const liveKitElement = $('[data-live-kit-token]');
  const liveKitDataClass = liveKitElement.data('live-kit-welcome-meeting-class');
  const liveKitClass = liveKitDataClass || 'col';
  videoContainer.className = `${liveKitClass} video-conference-participant-container`;

  videoContainer.appendChild(video);

  const videoFooter = document.createElement("div");
  videoFooter.className = 'video-footer d-flex align-items-center';

  // Name Element
  const nameElement = document.createElement("div");
  nameElement.className = 'video-footer-name';
  nameElement.innerText = participant.name;
  videoFooter.appendChild(nameElement);

  if (facilitatorControls) {
    console.log("Enabling facilitator controls: " + facilitatorControls);
    const controls = document.createElement("div");
    controls.className = 'video-footer-controls';
    controls.appendChild(muteMic);
    controls.appendChild(muteVideo);
    controls.appendChild(evict);
    videoFooter.appendChild(controls);
  }

  videoContainer.appendChild(videoFooter);

  let container = videoContainer;

  // Parent container for responsiveness
  if (hasResponsiveParent) {
    const parentContainer = document.createElement("div");
    parentContainer.id = 'video-conference-you-container-parent';
    parentContainer.className = 'col';
    parentContainer.appendChild(videoContainer);
    container = parentContainer;
  }

  // Add video to page
  if (track.source === 'screen_share') {
    $('#livekit-screen-share-target').prepend(container);
    if (document.getElementById('livekit-screen-share-instructions')) {
      document.getElementById('livekit-screen-share-instructions').classList.add('d-none');
    }
    document.getElementById('livekit-screen-share-target').classList.remove('d-none');
  } else {
    $('#' + liveKitRemoteParticipantTarget).append(container);
  }
}

// Make AJAX PUT request to trigger a participant action by a facilitator
function triggerParticipantAction(liveKitFacilitatorControlsBaseUrl, user_id, action) {
  $.ajax({
    url: liveKitFacilitatorControlsBaseUrl + '/participants/' + user_id,
    method: 'PUT',
    dataType: 'json',
    data: {
      user_id: user_id,
      participant_action: action
    }
  }).done(function (data, status) {
    if (data.success) {
      console.log(`Action ${data.participant_action} completed successfully.`);
    } else {
      console.log(`Action ${data.participant_action} failed.`);
    }
  });
}



document.addEventListener("DOMContentLoaded", (e) => {
  const waitingToJoinElement = $('[data-waiting-to-join]');
  if (waitingToJoinElement.length === 0) {
    // Exit if the page isn't waiting to join
    return;
  }
  consumer.subscriptions.create(
    {channel: 'SessionControlsChannel', id: 1},
    {
      connected() {
        console.log('Connected to SessionControlsChannel');
      },
      disconnected() {
        console.log('Disconnected from SessionControlsChannel');
      },
      received(data) {
        if (data.action === 'begin-session') {
          window.location = $(waitingToJoinElement).data('join-url');
        }
      }
    });
});

document.addEventListener("DOMContentLoaded", (e) => {
  const liveKitElement = $('[data-live-kit-token]');
  if (liveKitElement.length === 0) {
    // Exit if the page isn't using LiveKit
    return;
  }

  console.log("LiveKit token detected");

  // Extract live kit data items
  const liveKitToken = liveKitElement.data('live-kit-token');
  const liveKitWsUrl = liveKitElement.data('live-kit-ws-url');
  const liveKitFacilitatorControlsBaseUrl = liveKitElement.data('live-kit-facilitator-controls-base-url');
  const liveKitLocalParticipantTarget = liveKitElement.data('live-kit-local-participant-target');
  const liveKitRemoteParticipantTarget = liveKitElement.data('live-kit-remote-participant-target');
  const facilitatorControls = liveKitElement.data('live-kit-facilitator-controls');
  const hasResponsiveParent = !!liveKitElement.data('live-kit-video-has-responsive-parent');
  const sessionUrl = liveKitElement.data('live-kit-session-url');

  // Join call
  const appDialogEl = document.querySelector('#app-begin-session-prompt-dialog');
  const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static', keyboard: false });
  dialog.show();
  $(".app-begin-session-prompt-dialog-join-session").click(function() {
    dialog.hide();
    joinVideoConferencing(
      liveKitWsUrl, liveKitToken, facilitatorControls, liveKitFacilitatorControlsBaseUrl,
      liveKitLocalParticipantTarget, liveKitRemoteParticipantTarget, hasResponsiveParent,
      sessionUrl
    ).catch(handleLivekitError);
  })
});

const handleLivekitError = (livekitError) => {
  console.error(livekitError);

  let title = 'Failed to join session';
  let message;

  // Livekit errors doc: https://docs.livekit.io/client-sdk-js/classes/LivekitError.html
  if (livekitError instanceof ConnectionError) {
    switch (livekitError.reason?.toString()) {
      case 'NotAllowed':
      case 'InternalError':
      case 'ServerUnreachable':
      default:
        message = `Your video call session has been interrupted.\n
                   Please refresh the page and if the problem persists, please contact info@icanconnect.co.uk`;
        break;
    }
  } else {
    message = `Something went wrong with your video call. Valley Leisure video calls requires access to your camera.\n
               Please check if your camera is not blocked in your browser's address bar.\n
               If the problem persists, please contact info@icanconnect.co.uk`;
  }

  // TODO: Handle maybe?
  // } else if (livekitError instanceof TrackInvalidError) {
  // } else if (livekitError instanceof NegotiationError) {
  // } else if (livekitError instanceof TrackInvalidError) {
  // } else if (livekitError instanceof PublishDataError) {
  // } else if (livekitError instanceof UnsupportedServer) {
  // } else if (livekitError instanceof UnexpectedConnectionState) {

  showAlertDialog(title, message);
}

const showAlertDialog = (title, message) => {
  const appDialogEl = document.querySelector('#app-alert-dialog');
  appDialogEl.querySelector('#app-alert-dialog--header').innerText = title;
  appDialogEl.querySelector('#app-alert-dialog--message').innerText = message;

  const dialog = bootstrap.Modal.getOrCreateInstance(appDialogEl, { backdrop: 'static' });
  dialog.show();
}
