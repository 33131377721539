import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import moment from 'moment'
import DatePicker from 'tui-date-picker'

export default class extends Controller {
  static targets = [
    'header',
    'date',

    'startTime',
    'endTime',
    'timesMessage',

    'slotType',
    'forWelcomeMeetingSession',
    'forReviewSession',
    'forGoalSettingSession',
    'forConnectedSession',

    'deleteButton',
    'submitButton',

    'repeatContainer',
    'repeatWeekContainer',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'endRepeatDate',
    'repeatBookingInput',
    'repeatBookingLabel',
    'repeatInterval',
    'repeatOnDayInput',
  ]

  ////////////////////////////////////////////////////////////////////////////////

  renderForm() {
    if (this.repeatIntervalTarget.value === 'week') {
      $(this.repeatWeekContainerTarget).removeClass('d-none')
    } else {
      $(this.repeatWeekContainerTarget).addClass('d-none')
    }

    if (this.repeatBookingInputTarget.checked) {
      $(this.repeatContainerTarget).removeClass('d-none')
      $(this.repeatBookingLabelTarget).text('Repeat event')
    } else {
      $(this.repeatContainerTarget).addClass('d-none')
      $(this.repeatBookingLabelTarget).text('Single event')
    }
  }

  // Clicking anywhere in the parent div triggers the radio
  updateEndConfigState(e) {
    const target = $(e.target)
    const thisOption = target.closest('.end-option')
    if (target.is(':not(input:radio)')) {
      thisOption.find('input:radio').trigger('click')
    }
  }

  ////////////////////////////////////////////////////////////////////////////////

  connect() {
    this.startDatePicker = this.initDatePicker(this.startDateTarget);
    this.endDatePicker = this.initDatePicker(this.endDateTarget);
    this.endRepeatDatePicker = this.initDatePicker(this.endRepeatDateTarget);

    $('.end-option').click(this.updateEndConfigState);
    this.renderForm();

    this.element.addEventListener('hidden.bs.modal', () => {
      // On modal hide
      if (this.creatingSchedule) {
        this.creatingSchedule.guide.clearGuideElement();
      }

      this.#reset();
    });
  }

  initDatePicker(target) {
    const rangeStart = moment();
    const rangeEnd = moment().add(13, 'months');

    const datePicker = new DatePicker($(target).data('container'), {
      ...(target.value && { date: Date.parse(target.value) }),
      input: {
        element: target,
        format: 'D, dd MMM YYYY',
      },
      calendar: {
        showToday: false,
      },
      weekStartDay: 'Mon',
      selectableRanges: [[rangeStart, rangeEnd]],
    });

    datePicker.on('change', () => {
      const event = new Event('change', { bubbles: true });
      target.dispatchEvent(event);
    });

    return datePicker;
  }

  ////////////////////////////////////////////////////////////////////////////////

  onDelete(event) {
    event.preventDefault();

    if (this.isEditing && this.editingSchedule) {
      if (!this.editingSchedule.raw.canDelete) {
        alert("You can't delete appointment slots");
        this.#closeModal();
        return;
      }

      // Delete actual schedule
      this.dispatch('delete', { detail: { schedule: this.editingSchedule } });
    }

    this.#closeModal();
  }

  #readRepeatOptions(formElement) {
    const data = new FormData(formElement)
    return {
      repeatBooking:      data.getAll('appointment_slot[repeat][repeat_booking]').length > 1,
      repeatValue:        data.get('appointment_slot[repeat][repeat_value]'),
      repeatInterval:     data.get('appointment_slot[repeat][repeat_interval]'),
      dayNames:           JSON.stringify(data.getAll('appointment_slot[repeat][day_names][]')),
      endRepeatDate:      data.get('appointment_slot[repeat][end_repeat_date]'),
      ends:               data.get('appointment_slot[repeat][ends]'),
      occurrenceQuantity: data.get('appointment_slot[repeat][occurrence_quantity]'),
    }
  }

  #formDataRepeatOptions(data, repeatOptions) {
    data.append('[appointment_slot][repeat][repeat_booking]', repeatOptions.repeatBooking)
    data.append('[appointment_slot][repeat][repeat_value]', repeatOptions.repeatValue)
    data.append('[appointment_slot][repeat][repeat_interval]', repeatOptions.repeatInterval)
    data.append('[appointment_slot][repeat][day_names]', repeatOptions.dayNames)
    data.append('[appointment_slot][repeat][end_repeat_date]', repeatOptions.endRepeatDate)
    data.append('[appointment_slot][repeat][ends]', repeatOptions.ends)
    data.append('[appointment_slot][repeat][occurrence_quantity]', repeatOptions.occurrenceQuantity)
    return data
  }

  // Take a response.data object from the server and convert it into an array of schedules.
  #arrayFromRails(responseArray) {
    let schedules = []

    responseArray.forEach(function(data) {
      const startTime = data.attributes['start-time']
      const endTime = data.attributes['end-time']
      const schedule = {
        category: 'time',
        raw: {
          historyStart: [startTime],
          historyEnd: [endTime]
        }
      }
      schedule.id = data.id
      schedule.calendarId = data.attributes['calendar-id']
      schedule.title = data.attributes['event-title']
      schedule.start = startTime
      schedule.end = endTime
      schedule.raw.className = data.attributes['class-name']
      schedule.raw.updateUrl = data.attributes['update-url']
      schedule.raw.showUrl = data.attributes['show-url']
      schedule.raw.formModel = data.attributes['form-model']
      schedule.raw.canUpdate = data.attributes['can-update']
      schedule.raw.canDelete = data.attributes['can-delete']
      schedule.raw.sessionContentId = data.attributes['session-content-id']
      schedule.raw.facilitatorId = data.attributes['facilitator-id']
      schedule.raw.facilitatorName = data.attributes['facilitator-name']
      schedule.raw.participantMax = data.attributes['participant-max']
      schedule.raw.calendarSessionUrl = data.attributes['calendar-session-url']
      schedule.raw.slotType = data.attributes['slot-type']
      schedule.raw.forWelcomeMeetingSession = data.attributes['for-welcome-meeting-session']
      schedule.raw.forReviewSession = data.attributes['for-review-session']
      schedule.raw.forGoalSettingSession = data.attributes['for-goal-setting-session']
      schedule.raw.forConnectedSession = data.attributes['for-connected-session']

      schedules.push(schedule)
    }, this)

    return schedules
  }

  onSubmit(event) {
    event.preventDefault();

    if (!this.isEditing) {
      // Create
      const { createUrlPath } = this.element.dataset;

      const startTime = this.#getStartTime().toISOString();
      const endTime = this.#getEndTime().toISOString();
      const schedule = {
        calendarId: this.calendarId,
        title: 'Staff appointment slots',
        category: 'time',
        start: startTime,
        end: endTime,
        raw: {
          historyStart: [startTime],
          historyEnd: [endTime]
        }
      }

      const data = new FormData();
      data.append('[appointment_slot]start_time', startTime);
      data.append('[appointment_slot]end_time', endTime);
      data.append('[appointment_slot]for_welcome_meeting_session', this.forWelcomeMeetingSessionTarget.checked);
      data.append('[appointment_slot]for_review_session', this.forReviewSessionTarget.checked);
      data.append('[appointment_slot]for_goal_setting_session', this.forGoalSettingSessionTarget.checked);
      data.append('[appointment_slot]for_connected_session', this.forConnectedSessionTarget.checked);

      const repeatOptions = this.#readRepeatOptions(event.target)
      this.#formDataRepeatOptions(data, repeatOptions)

      Rails.ajax({
        type: 'POST',
        url: createUrlPath,
        data: data,
        success: (res) => {

          if (Array.isArray(res.data)) {
            const schedules = this.#arrayFromRails(res.data)

            if (this.calendar) {
              this.calendar.createSchedules(schedules);
            }

          } else {
            const createdSchedule = res.data;

            schedule.id = createdSchedule.id;
            schedule.calendarId = createdSchedule.attributes['calendar-id'];
            schedule.title = createdSchedule.attributes['event-title'];
            schedule.raw.className = createdSchedule.attributes['class-name'];
            schedule.raw.updateUrl = createdSchedule.attributes['update-url'];
            schedule.raw.showUrl = createdSchedule.attributes['show-url'];
            schedule.raw.formModel = createdSchedule.attributes['form-model'];
            schedule.raw.canUpdate = createdSchedule.attributes['can-update'];
            schedule.raw.canDelete = createdSchedule.attributes['can-delete'];
            schedule.raw.sessionContentId = createdSchedule.attributes['session-content-id'];
            schedule.raw.facilitatorId = createdSchedule.attributes['facilitator-id'];
            schedule.raw.facilitatorName = createdSchedule.attributes['facilitator-name'];
            schedule.raw.participantMax = createdSchedule.attributes['participant-max'];
            schedule.raw.calendarSessionUrl = createdSchedule.attributes['calendar-session-url'];
            schedule.raw.slotType = createdSchedule.attributes['slot-type'];
            schedule.raw.forWelcomeMeetingSession = createdSchedule.attributes['for-welcome-meeting-session'];
            schedule.raw.forReviewSession = createdSchedule.attributes['for-review-session'];
            schedule.raw.forGoalSettingSession = createdSchedule.attributes['for-goal-setting-session'];
            schedule.raw.forConnectedSession = createdSchedule.attributes['for-connected-session'];

            if (this.calendar) {
              this.calendar.createSchedules([schedule]);
            }
          }

          this.dispatch('flash', { detail: { message: 'The calendar event has been successfully created.' } });
        },
        error: (err) => {
          console.error(err);
          this.dispatch('flash', { detail: { message: 'Something went wrong while creating the appointment slot. Please try again and if the problem persists, contact the team' } });
        }
      });

    } else if (this.isEditing && this.editingSchedule) {
      if (!this.editingSchedule.raw.canUpdate) {
        alert("You can't update your appointment slots");
        this.#closeModal();
        return;
      }

      // Update
      const changes = {};
      changes.forWelcomeMeetingSession = this.forWelcomeMeetingSessionTarget.checked
      changes.forReviewSession = this.forReviewSessionTarget.checked
      changes.forGoalSettingSession = this.forGoalSettingSessionTarget.checked
      changes.forConnectedSession = this.forConnectedSessionTarget.checked
      if (this.#hasTimeRangeChanged()) {
        changes.start = this.#getStartTime().toISOString();
        changes.end = this.#getEndTime().toISOString();
      }

      const repeatOptions = this.#readRepeatOptions(event.target)
      const formData = this.#formDataRepeatOptions(new FormData(), repeatOptions)

      const eventArgs = {
        schedule: this.editingSchedule,
        changes: changes,
        formData: formData
      }
      this.dispatch('update', { detail: { event: eventArgs } });
    }

    // Dismiss the modal
    this.#closeModal();
  }

  show({ detail }) {
    this.calendar = detail.calendar;

    if (detail.schedule) {
      // Editing an existing schedule
      this.isEditing = true;
      this.editingSchedule = detail.schedule;

      this.#showForEditing();

    } else if (detail.creatingSchedule) {
      // Creating a new schedule
      this.isEditing = false;
      this.creatingSchedule = detail.creatingSchedule;

      this.#showForCreating();
    }
  }

  #showForEditing() {
    if (!this.editingSchedule) {
      throw new Error('There is no schedule to edit.');
    }

    this.#setupHeader();

    this.currentStartTime = this.editingSchedule.start.toDate();
    this.currentEndTime = this.editingSchedule.end.toDate();

    this.dateTarget.valueAsDate = this.#getDate();
    this.startTimeTarget.valueAsDate = this.#getStartTime().utc(true).toDate();
    this.endTimeTarget.valueAsDate = this.#getEndTime().utc(true).toDate();

    this.forWelcomeMeetingSessionTarget.checked = this.editingSchedule.raw.forWelcomeMeetingSession
    this.forReviewSessionTarget.checked = this.editingSchedule.raw.forReviewSession
    this.forGoalSettingSessionTarget.checked = this.editingSchedule.raw.forGoalSettingSession
    this.forConnectedSessionTarget.checked = this.editingSchedule.raw.forConnectedSession

    if (!this.editingSchedule.raw.canUpdate) {
      // Can't update schedule details - not permitted, hide submit button and disable all other input controls
      this.#setElementVisible(this.submitButtonTarget, false);
      this.#setElementEnabled(this.submitButtonTarget, false);

      this.#setElementEnabled(this.startTimeTarget, false);
      this.#setElementEnabled(this.endTimeTarget, false);

    } else {
      // Can submit, so setup the button
      this.submitButtonTarget.classList.remove('btn-primary');
      this.submitButtonTarget.classList.add('btn-secondary');
      this.submitButtonTarget.value = 'Update';
    }

    if (!this.editingSchedule.raw.canDelete) {
      // Can't delete this scshedule - not permitted, hide delete button.
      this.#setElementVisible(this.deleteButtonTarget, false);
      this.#setElementEnabled(this.deleteButtonTarget, false);
    }

    // Show the modal now that all is setup!
    $(this.element).modal('show');
  }

  #showForCreating() {
    if (!this.creatingSchedule) {
      throw new Error('There is no schedule intent to create.');
    }

    this.#setupHeader();

    this.currentStartTime = this.creatingSchedule.start.toDate();
    this.currentEndTime = this.creatingSchedule.end.toDate();

    this.dateTarget.valueAsDate = this.#getDate();
    this.startTimeTarget.valueAsDate = this.#getStartTime().utc(true).toDate();
    this.endTimeTarget.valueAsDate = this.#getEndTime().utc(true).toDate();

    this.forWelcomeMeetingSessionTarget.checked = false
    this.forReviewSessionTarget.checked = false
    this.forGoalSettingSessionTarget.checked = false
    this.forConnectedSessionTarget.checked = false

    this.submitButtonTarget.classList.remove('btn-secondary');
    this.submitButtonTarget.classList.add('btn-primary');
    this.submitButtonTarget.value = 'Add';

    this.deleteButtonTarget.classList.remove('btn-danger');
    this.deleteButtonTarget.classList.add('btn-dark');
    this.deleteButtonTarget.innerText = 'Discard';

    // Show the modal now that all is setup!
    $(this.element).modal('show');
  }

  #getDate() {
    return this.#getStartTime().utc(true).startOf('day').toDate();
  }

  #getStartTime() {
    return moment(this.currentStartTime);
  }

  #getEndTime() {
    return moment(this.currentEndTime);
  }

  #closeModal() {
    $(this.element).modal('hide')
  }

  /**
   * Validates the entered form
   *
   * @returns `true` if valid, otherwise `false`
   */
  #canSubmitForm() {
    // Can't submit the form if editing and no values has changed
    if (!this.#hasTimeRangeChanged()) {
      return false;
    }

    // Can submit the form if changed values are valid
    if (!this.#isTimeRangeValid()) {
      return false;
    }

    // All good, it seems.
    return true;
  }

  /**
   * Whenever start / end times are changed
   */
  onTime() {
    this.currentStartTime = moment(`${this.dateTarget.value} ${this.startTimeTarget.value}`, 'YYYY-MM-DD HH:mm');
    this.currentEndTime = moment(`${this.dateTarget.value} ${this.endTimeTarget.value}`, 'YYYY-MM-DD HH:mm');

    // Validate
    if (!this.#isTimeRangeValid()) {
      this.#checkIfCanSubmit();
      return;
    }

    this.#checkIfCanSubmit();
  }

  /**
   * On any input value change
   */
  #checkIfCanSubmit() {
    const canSubmitForm = this.#canSubmitForm();
    this.#setElementEnabled(this.submitButtonTarget, canSubmitForm);
  }

  #isTimeRangeValid() {
    const updateValidity = !this.startTimeTarget.hasAttribute('disabled') || !this.endTimeTarget.hasAttribute('disabled');

    // If we're editing the schedule and time range hasn't changed
    // then assume valid
    if (!this.#hasTimeRangeChanged()) {
      this.#resetInputValidity(this.startTimeTarget, this.timesMessageTarget);
      this.#resetInputValidity(this.endTimeTarget, this.timesMessageTarget);
      return true;
    }

    if (updateValidity) {
      this.#setInputValidity(this.startTimeTarget, true);
      this.#setInputValidity(this.endTimeTarget, true, this.timesMessageTarget);
    }
    return true;
  }

  #hasTimeRangeChanged() {
    // When editing, has the start / end times changed?
    if (this.isEditing && this.editingSchedule) {
      const originalStart = moment(this.editingSchedule.start.toDate());
      const originalEnd = moment(this.editingSchedule.end.toDate());

      const currentStart = this.#getStartTime();
      const currentEnd = this.#getEndTime();

      if (originalStart.isSame(currentStart) && originalEnd.isSame(currentEnd)) {
        // No, no changes
        return false;
      }
    }

    // Yes, either we're not in edit mode, or it hasn't changed!
    return true;
  }

  #setupHeader() {
    if (this.isEditing) {
      this.slotTypeTarget.innerHTML = this.#htmlForSlotType(
        this.editingSchedule.raw.slotType,
        this.editingSchedule.raw.calendarSessionUrl
      )

      // Header when editing an existing schedule
      if (this.editingSchedule.raw.showUrl) {
        const linkToEvent = document.createElement('a')
        linkToEvent.href = this.editingSchedule.raw.showUrl
        linkToEvent.rel = 'noopenner'
        linkToEvent.target = '_blank'
        linkToEvent.innerText = this.editingSchedule.title
        this.headerTarget.innerHTML = linkToEvent.outerHTML

      } else {
        this.headerTarget.innerHTML = this.editingSchedule.title
      }

    } else {
      // Header when creating a new schedule
      this.headerTarget.innerText = 'Add new appointment slot';
      this.slotTypeTarget.innerHTML = ''
    }
  }

  #htmlForSlotType(slotType, calendarSessionUrl) {
    switch(slotType) {
      case 'available':
        return '<span class="badge text-bg-light">Available</span>'
      case 'booked_review':
        return `<a target='_blank' class='btn btn-success' href='${calendarSessionUrl}'><i class="fas fa-fw fa-magnifying-glass me-1"></i>Review Session</a>`
      case 'booked_induction':
        return `<a target='_blank' class='btn btn-info' href='${calendarSessionUrl}'><i class="fas fa-fw fa-handshake me-1"></i>Welcome Meeting</a>`
      case 'booked_connected':
        return `<a target='_blank' class='btn btn-primary' href='${calendarSessionUrl}'><i class="fas fa-fw fa-link me-1"></i>Connected Session</a>`
      case 'booked_goal_setting':
        return `<a target='_blank' class='btn btn-warning' href='${calendarSessionUrl}'><i class="fas fa-fw fa-list-check me-1"></i>Goal Setting Session</a>`
      default:
        return '<span class="badge text-bg-secondary">Unknown</span>'
    }
  }

  #reset() {
    // Clear all values
    this.editingSchedule = null;
    this.creatingSchedule = null;

    this.startTimeTarget.value = '';
    this.endTimeTarget.value = '';

    this.deleteButtonTarget.value = '';
    this.submitButtonTarget.value = '';

    this.#resetInputValidity(this.startTimeTarget, this.timesMessageTarget);
    this.#resetInputValidity(this.endTimeTarget, this.timesMessageTarget);
  }

  #setInputValidity(inputElement, isValid, messageElement, message) {
    if (isValid) {
      inputElement.classList.add('is-valid');
      inputElement.classList.remove('is-invalid');
    } else {
      inputElement.classList.add('is-invalid');
      inputElement.classList.remove('is-valid');
    }

    if (!messageElement) {
      return;
    }

    if (!isValid && message) {
      this.#setElementVisible(messageElement);
      messageElement.innerText = message;

    } else {
      this.#setElementVisible(messageElement, false);
      messageElement.innerText = '';
    }
  }

  #resetInputValidity(inputElement, messageElement) {
    inputElement.classList.remove('is-valid', 'is-invalid');

    if (messageElement) {
      this.#setElementVisible(messageElement, false);
      messageElement.innerText = '';
    }
  }

  #setElementVisible(element, isVisible = true) {
    element.classList.toggle('d-none', !isVisible);
    element.classList.toggle('d-block', isVisible);
  }

  #setElementEnabled(element, isEnabled = true) {
    element.toggleAttribute('disabled', !isEnabled);
  }
}
