import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'descriptionError' ]

  static values = {
    url: String
  }

  patch(event) {
    const element = event.target
    const value = element.value
    const name = element.getAttribute('name')
    const field = /\[([^)]+)\]/.exec(name)[1]
    const formData = new FormData()
    formData.append(name, value)

    Rails.ajax({
      type: 'PATCH',
      url: this.urlValue,
      data: formData,
      success: () => {
        element.classList.add('is-valid')
        element.classList.remove('is-invalid')
        if (field === 'description') {
          this.descriptionErrorTarget.classList.add('d-none')
        }
      },
      error: () => {
        element.classList.remove('is-valid')
        element.classList.add('is-invalid')
        if (field === 'description') {
          this.descriptionErrorTarget.classList.remove('d-none')
        }
      }
    })
  }
}
