$(document).ready(function () {

  $('[data-media-player-url]').each(function(_, mediaPlayerElement) {
    const myOptions = {
      autoplay: false,
      controls: true,
      /**
       * Take full width of the parent container and height
       * will be adjusted to fit a video with a standard 16:9 aspect ratio.
       */
      fluid: true,
      poster: '',
      logo: { enabled: false },
    };

    const mediaPlayerUrl = $(this).data('media-player-url');
    const reportingUrl = $(this).data('media-player-reporting-url');
    const initialVolume = $(this).data('media-player-initial-volume');

    const myPlayer = amp(mediaPlayerElement, myOptions);

    if(typeof initialVolume !== 'undefined') {
      myPlayer.volume(initialVolume);
    }

    myPlayer.src(
      [
        {
          src: mediaPlayerUrl,
          type: 'application/vnd.ms-sstr+xml'
        },
      ]
    );

    let startTime = 0;
    myPlayer.addEventListener(amp.eventName.play, function (evt) {
      startTime = myPlayer.currentTime();
    });

    myPlayer.addEventListener(amp.eventName.pause, function (evt) {
      const elapsedTime = myPlayer.currentTime() - startTime;
      if (elapsedTime > 0 && reportingUrl !== undefined) {
        $.ajax({
          type: 'POST',
          url: reportingUrl,
          data: {
            elapsed_time_in_seconds: elapsedTime
          },
          dataType: 'json',
          async:false
        });
      }
    });
  });

});
